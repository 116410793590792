import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react'
import {useRef} from 'react'

interface DeleteConfirmationModalProps {
	open: boolean
	itemType: string
	onClose: () => void
	onDelete: () => void
}

export const DeleteConfirmationModal = ({open, itemType, onClose, onDelete}: DeleteConfirmationModalProps) => {
	const cancelButtonRef = useRef(null)

	return (
		<Dialog
			as="div"
			className="relative z-10"
			initialFocus={cancelButtonRef}
			onClose={onClose}
			open={open}
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-black/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
			/>

			<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<DialogPanel className="relative w-full max-w-[500px] transform overflow-hidden bg-white text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
						<div className="flex flex-col p-[24px]">
							<DialogTitle className="font-lead text-[24px] leading-[120%] text-uom-heritage-100">Delete {itemType}</DialogTitle>
							<div className="mt-[8px] text-[18px] leading-[140%]">
								<p className="">This action can’t be undone.</p>
								<p className="mt-[1rem]">Are you sure you want to delete the {itemType}?</p>
							</div>
						</div>
						<div className="flex items-center justify-end gap-x-[8px] bg-uom-grey-light-25 px-[12px] py-[8px] text-[14px] font-[700] text-uom-heritage-100">
							<button
								type="button"
								className="h-[32px] border border-uom-heritage-100 px-[12px] hover:bg-uom-blue-light-25 active:bg-uom-blue-light-100"
								onClick={onClose}
								ref={cancelButtonRef}
							>
								Cancel
							</button>
							<button
								type="button"
								className="h-[32px] border border-uom-red-light-50 bg-uom-red-light-50 px-[12px] hover:border-uom-red-light-25 hover:bg-uom-red-light-25 active:border-uom-red-light-75 active:bg-uom-red-light-75"
								onClick={onDelete}
							>
								Yes, delete
							</button>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	)
}
