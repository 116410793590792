import {createFileRoute, Outlet, useLocation, useNavigate, useParams} from '@tanstack/react-router'
import {getDefaultStore, useAtom} from 'jotai'
import {useEffect, useRef} from 'react'
import {useDeleteThread, useHasOnboarded} from '~/api.ts'
import {DeleteConfirmationModal} from '~/components/DeleteConfirmationModal.tsx'
import {OnboardingWizard} from '~/components/OnboardingWizard'

import {PromptInput} from '~/components/PromptInput.tsx'
import {StudentSiteDisabled} from '~/components/SiteDisabled.tsx'
import {ThreadMenu} from '~/components/ThreadMenu'
import {ThreadsLeftSidebar} from '~/components/ThreadsLeftSidebar'
import {ThreadsTabs} from '~/components/ThreadsTabs'
import {deleteThreadIdAtom, scrollToBottomOfMessageAtom} from '~/state.ts'

export const Route = createFileRoute('/course/$courseId/_student-layout')({
	component: StudentLayout,
})
function StudentLayout() {
	const {courseId} = Route.useParams()
	const {threadId} = useParams({strict: false})

	const scrollContainerRef = useRef<HTMLDivElement>(null)

	const [deleteThreadId, setDeleteThreadId] = useAtom(deleteThreadIdAtom)
	const hasOnboarded = useHasOnboarded()

	const {mutateAsync: deleteThread} = useDeleteThread()
	const navigate = useNavigate()
	const location = useLocation()

	function scrollToBottom() {
		scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight)
	}
	// Because the ref can't be passed though the Outlet
	useEffect(() => {
		const unsubscribe = getDefaultStore().sub(scrollToBottomOfMessageAtom, scrollToBottom)
		return () => {
			unsubscribe()
		}
	}, [])

	return (
		<>
			<div className="flex h-full">
				<ThreadsLeftSidebar />
				<div className="flex grow flex-col justify-between">
					<StudentSiteDisabled>
						<ThreadsTabs />
						<div className="relative flex flex-1 flex-col items-center">
							<div
								ref={scrollContainerRef}
								className="h-full w-full overflow-y-auto overflow-x-hidden px-[16px] md:px-[24px]"
							>
								<div className="mx-auto flex w-full max-w-[724px] flex-col pt-[16px] md:py-[24px]">
									<div className="absolute right-[24px] top-[16px] flex flex-col items-end gap-[12px] md:top-[24px] lg:right-[82px]">
										<ThreadMenu threadId={threadId} />
									</div>
									<Outlet />
								</div>
							</div>
						</div>
					</StudentSiteDisabled>
					<div className="max-h-max px-[16px] pt-[12px] md:pt-[24px]">
						<PromptInput />
					</div>
				</div>

				<DeleteConfirmationModal
					open={deleteThreadId != null}
					itemType="thread"
					onClose={() => {
						setDeleteThreadId(null)
					}}
					onDelete={() => {
						void (async () => {
							await deleteThread()
							if (location.pathname === `/threads/${deleteThreadId}`) {
								void navigate({
									to: '/course/$courseId/chat',
									params: {courseId},
								})
							}
							setDeleteThreadId(null)
						})()
					}}
				/>
			</div>
			{!hasOnboarded && <OnboardingWizard />}
		</>
	)
}
