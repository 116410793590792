import {QueryClient, useMutation, useQuery} from '@tanstack/react-query'
import {useParams} from '@tanstack/react-router'
import axios from 'axios'
import {produce} from 'immer'
import {useAtom} from 'jotai'
import {
	Profile,
	type BotConfig,
	type BotConfigUpdate,
	type Context,
	type DataSet,
	type DataSource,
	type DataSourcesResponse,
	type Module,
	type ModuleItem,
	type Project,
	type ProjectChangelogEntry,
	type RawContextAttributes,
	type Thread,
	type ThreadSummery,
} from '~/model.ts'
import {deleteThreadIdAtom, pendingMessageAtom, reshowOnboardingAtom, selectedResourcesAtom} from '~/state.ts'
import {decodeJwt} from '~/utils/utils'
import {Route} from './routes/course.$courseId'

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL ?? ''

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 100,
			retry: 2,
		},
	},
})

export const axiosAuthInstance = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		Accept: 'application/json',
	},
})

// Intercept all requests and add the auth token
axiosAuthInstance.interceptors.request.use((config) => {
	const token = localStorage.getItem('token')

	if (token == null || token.length == 0) {
		return Promise.reject(new Error('No token'))
	}

	const jwt = decodeJwt(token)
	if (jwt.payload.exp < Date.now() / 1000) {
		return Promise.reject(new Error('Token expired'))
	}

	config.headers.Authorization = `Bearer ${token}`
	return config
})

export function useApiContext() {
	const {courseId} = Route.useParams()
	return useQuery({
		queryKey: ['context', courseId],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<Context>(`/auth/context`)
			data.context.rawContextAttributes = JSON.parse(data.context.raw_context_attributes ?? '{}') as RawContextAttributes
			data.context.raw_context_attributes = undefined
			return data
		},
		refetchOnReconnect: 'always',
	})
}

export function useModules() {
	const {courseId} = Route.useParams()
	return useQuery({
		queryKey: ['course', courseId, 'modules'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<Module[]>(`/${courseId}/modules`)
			return data
		},
		select: (data) => {
			return data.map((module) => addItemTypeCountToModule(module))
		},
		initialData: [],
		staleTime: 0,
	})
}

export function useIndexModules() {
	const {courseId} = Route.useParams()
	const botConfig = useBotConfig()
	const [selectedResources, setSelectedResources] = useAtom(selectedResourcesAtom)
	const mutation = useMutation({
		mutationFn: async () => {
			const {data} = await axiosAuthInstance.post<Module[]>(`/${courseId}/modules/index?onlyIncludePublishedModules=true&onlyIncludePublishedModuleItems=false`)
			return data
		},
		onSuccess: (data) => {
			queryClient.setQueryData(
				['course', courseId, 'modules'],
				data.map((module) => addItemTypeCountToModule(module)),
			)
			if (botConfig?.dataset_id == null && Object.keys(selectedResources).length == 0) {
				const next: Record<string, boolean> = {}
				data.forEach((module) => {
					module.items.forEach((item) => {
						next[item.id] = true
						item.children?.forEach((child) => {
							next[child.id] = true
						})
					})
				})
				setSelectedResources(next)
			}
		},
	})
	return {indexModules: mutation.mutateAsync, indexModulesIsPending: mutation.isPending}
}

function addItemTypeCountToModule(module: Module) {
	let numberOfChildPages = 0
	let numberOfChildFiles = 0

	function traverseItems(items: ModuleItem[]) {
		for (const item of items) {
			if (item.children) {
				traverseItems(item.children)
			}
			if (item.type === 'page') {
				numberOfChildPages++
			}
			if (item.type === 'file') {
				numberOfChildFiles++
			}
		}
	}

	traverseItems(module.items)
	return {...module, numberOfChildPages, numberOfChildFiles}
}

const MS_UNTIL_AUTO_UNLOCK = 1000 * 60 * 60 * 24
const ONE_HOUR_MS = 1000 * 60 * 60

export function processBotConfig(botConfig: BotConfig) {
	if (!botConfig.locked_at) return botConfig

	const autoUnlockAtMs = Date.parse(botConfig.locked_at) + MS_UNTIL_AUTO_UNLOCK

	return autoUnlockAtMs - Date.now() <= 0
		? {
				...botConfig,
				locked: false,
				locked_at: undefined,
				locked_by_user_name: undefined,
				is_locked_by_current_user: false,
				autoUnlockAtMs: undefined,
			}
		: {
				...botConfig,
				autoUnlockAtMs: Date.parse(botConfig.locked_at) + MS_UNTIL_AUTO_UNLOCK,
			}
}

export function useBotConfig() {
	const {courseId} = Route.useParams()
	const query = useQuery({
		queryKey: ['tutorbot', courseId, 'config'],
		queryFn: async () => {
			let response = await axiosAuthInstance.get<{botDetails: BotConfig}>(`/tutorbot/${courseId}/config`)
			// If the bot has not been created yet then create it
			if (response.status === 204) {
				response = await axiosAuthInstance.post<{botDetails: BotConfig}>(`/tutorbot/${courseId}/config`)
			}

			const previousConfig = queryClient.getQueryData<BotConfig>(['tutorbot', courseId, 'config'])
			if (previousConfig?.locked && !response.data.botDetails.locked) {
				void queryClient.invalidateQueries()
			}
			return processBotConfig(response.data.botDetails)
		},
		staleTime: (staleTimeProps) => {
			return staleTimeProps.state.data?.locked ? 60 * 1000 : ONE_HOUR_MS
		},
	})

	return query.data
}

export function useUpdateBotConfig() {
	const {courseId} = Route.useParams()
	return useMutation({
		mutationFn: async (botConfigUpdate: BotConfigUpdate) => {
			const {data} = await axiosAuthInstance.patch<{botDetails: BotConfig}>(`/tutorbot/${courseId}/config`, {botDetails: botConfigUpdate})
			return processBotConfig(data.botDetails)
		},
		onSuccess: (botConfig) => {
			queryClient.setQueryData(['tutorbot', courseId, 'config'], botConfig)
		},
	})
}

// DataSet

export function useDataSet() {
	const botConfig = useBotConfig()
	const query = useQuery({
		queryKey: ['dataSet', botConfig?.dataset_id],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{dataset: DataSet}>(`/datasets/${botConfig?.dataset_id}`)
			return data.dataset
		},
		enabled: botConfig?.dataset_id != null,
	})
	return {dataSet: query.data}
}

export function useDataSources() {
	const botConfig = useBotConfig()
	const query = useQuery({
		queryKey: ['dataSets', botConfig?.dataset_id, 'dataSources'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<DataSourcesResponse>(`/datasets/${botConfig?.dataset_id}/datasources`)
			return data.datasources
		},
		select: (dataSources) => {
			const dataSourcesById: Record<string, DataSource | undefined> = {}
			let hasErrors = false

			dataSources.forEach((dataSource) => {
				const uniqueTag = dataSource.unique_tag ?? dataSource.file_name.split('_')[0]
				dataSourcesById[uniqueTag] = dataSource
				if (dataSource.status === 'ERROR') {
					hasErrors = true
				}
			})
			return {dataSources, dataSourcesById, hasErrors}
		},
		enabled: botConfig?.dataset_id != null,
	})
	return {dataSources: query.data?.dataSources, dataSourcesById: query.data?.dataSourcesById, dataSourcesHasErrors: query.data?.hasErrors}
}

export function useUpdateDataSet() {
	const {courseId} = Route.useParams()
	return useMutation({
		mutationFn: async (selected: ModuleItem[]) => {
			const {data} = await axiosAuthInstance.put<{botDetails: BotConfig}>(`/tutorbot/${courseId}/dataset`, {moduleItems: selected})
			return processBotConfig(data.botDetails)
		},
		onSuccess: (botConfig) => {
			queryClient.setQueryData(['tutorbot', courseId, 'config'], botConfig)
		},
	})
}

// Project

export function useProject() {
	const botConfig = useBotConfig()
	return useQuery({
		queryKey: ['projects', botConfig?.project_id],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{project: Project}>(`/projects/${botConfig?.project_id}`)
			return data.project
		},
		enabled: botConfig?.project_id != null,
	})
}

export function useProjectChangelog(shouldFetch?: boolean) {
	const botConfig = useBotConfig()
	return useQuery({
		queryKey: ['projects', botConfig?.project_id, 'changelog'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{changelog: ProjectChangelogEntry[]}>(`/projects/${botConfig?.project_id}/changelog?key=prompt_ai_to_know&count=20`)
			return data.changelog.reverse()
		},
		enabled: shouldFetch && botConfig?.project_id != null,
	})
}

export function useProjectChangelogUserFullNames(changelog?: ProjectChangelogEntry[]) {
	const botConfig = useBotConfig()
	const usernames = Array.from(new Set(changelog?.map((entry) => entry.user))).join(',')
	return useQuery({
		queryKey: ['course', botConfig?.sis_course_id, 'usernames', usernames],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<Record<string, string>[]>(`/userFullNamesByCourse/${botConfig?.sis_course_id}?usernames=${usernames}`)
			return data.reduce<Record<string, string>>((acc, current) => {
				const [key, value] = Object.entries(current)[0]
				acc[key] = value
				return acc
			}, {})
		},
		enabled: changelog != null && changelog.length > 0,
	})
}

export function useUpdateProject() {
	return useMutation({
		mutationFn: async (project: Project) => {
			const {data} = await axiosAuthInstance.put<{project: Project}>(`/projects/${project.SK}`, {
				project,
			})
			return data.project
		},
		onSuccess: (data) => {
			void queryClient.invalidateQueries({queryKey: ['projects', data.SK, 'changelog']})
		},
	})
}

// Chat completion
export function useChatCompletion(socratic?: boolean) {
	const botConfig = useBotConfig()
	const {data: threadData} = useThread()
	const {data: project} = useProject()
	const [, setPendingMessage] = useAtom(pendingMessageAtom)
	return useMutation({
		mutationFn: async (variables: {message: string}) => {
			setPendingMessage(variables.message)
			const newThread =
				threadData == null
					? {
							SK: '',
							project: botConfig?.project_id,
							prompt_template_id: socratic ? 'SOCRATIC' : 'default',
							ui_messages: [
								{
									role: 'user',
									content: variables.message,
									llm_configuration: project?.llm_configuration,
								},
							],
						}
					: produce(threadData, (draft) => {
							draft.ui_messages.push({
								role: 'user',
								content: variables.message,
								llm_configuration: project?.llm_configuration,
							})
						})
			const {data} = await axiosAuthInstance.post<{thread: Thread}>(`/projects/${botConfig?.project_id}/ragchat`, {
				thread: newThread,
			})
			setPendingMessage(null)
			return data.thread
		},
		onSuccess: (data) => {
			/*			void queryClient.invalidateQueries({queryKey: ['projects', botConfig?.project_id, 'threads', data.SK]})
			void queryClient.invalidateQueries({queryKey: ['projects', botConfig?.project_id, 'threads']})*/

			queryClient.setQueryData(['projects', botConfig?.project_id, 'threads', data.SK], data)
			queryClient.setQueryData(['projects', botConfig?.project_id, 'threads'], (oldData: Thread[] | undefined) => {
				if (oldData == null) return [data]
				const index = oldData.findIndex((thread) => thread.SK === data.SK)
				if (index === -1) return [data, ...oldData]
				return produce(oldData, (draft) => {
					draft[index] = data
				})
			})
		},
	})
}

export interface TutorbotTopic {
	id: number
	project_id: string
	tutorbot_id: number
	content: string
}

export function useSubjectTopics() {
	const {courseId} = Route.useParams()

	return useQuery({
		queryKey: ['bot', courseId, 'topics'],
		queryFn: async () => {
			if (!courseId) {
				throw new Error('Course ID is required')
			}

			const {data} = await axiosAuthInstance.get<TutorbotTopic[]>(`/tutorbots/${courseId}/topics`)
			return data
		},
		enabled: !!courseId,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchInterval: false,
		staleTime: 1000 * 60 * 60, // 1 hour
	})
}

export function useSubjectQuestions() {
	const {courseId} = Route.useParams()

	return useQuery({
		queryKey: ['bot', courseId, 'questions'],
		queryFn: async () => {
			if (!courseId) {
				throw new Error('Course ID is required')
			}

			const response = await axios.get<string[]>(`/subject_questions/${courseId}.json`)
			if (response.status === 200 && response.headers['content-type'] === 'application/json') {
				return response.data
			}
			return []
		},
		enabled: !!courseId,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchInterval: false,
		staleTime: 1000 * 60 * 60, // 1 hour
	})
}

//  Thread

export function useThreads() {
	const botConfig = useBotConfig()
	return useQuery({
		queryKey: ['projects', botConfig?.project_id, 'threads'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{threads: ThreadSummery[]}>(`/projects/${botConfig?.project_id}/threads`)
			return data.threads
		},
		enabled: botConfig?.project_id != null,
	})
}

export function useThread() {
	const botConfig = useBotConfig()
	const {threadId} = useParams({strict: false})
	return useQuery({
		queryKey: ['projects', botConfig?.project_id, 'threads', threadId],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{thread: Thread}>(`/projects/${botConfig?.project_id}/threads/${threadId}`)
			return data.thread
		},
		enabled: botConfig?.project_id != null && threadId != undefined && threadId !== 'new',
	})
}

export const useDeleteThread = () => {
	const botConfig = useBotConfig()
	const [deleteThreadId] = useAtom(deleteThreadIdAtom)
	return useMutation({
		// Optimistic update
		onMutate: () => {
			queryClient.setQueryData(['projects', botConfig?.project_id, 'threads'], (oldData: Thread[] | undefined) => {
				if (oldData == null) return []
				return produce(oldData, (draft) => {
					const index = draft.findIndex((thread) => thread.SK === deleteThreadId)
					if (index !== -1) draft.splice(index, 1)
				})
			})
		},
		mutationFn: async () => {
			await axiosAuthInstance.delete(`/projects/${botConfig?.project_id}/threads/${deleteThreadId}`)
		},
	})
}

export const useRenameThread = () => {
	return useMutation({
		// Optimistic update
		onMutate: ({threadId, projectId, newName}) => {
			queryClient.setQueryData<Thread[] | undefined>(['projects', projectId, 'threads'], (oldData) => {
				if (oldData == null) return []
				return produce(oldData, (draft) => {
					const renamedThread = draft.find((thread) => thread.SK === threadId)
					if (renamedThread != null) {
						renamedThread.thread_name = newName
					}
				})
			})
			queryClient.setQueryData<Thread | undefined>(['projects', projectId, 'threads', threadId], (oldData) => {
				if (oldData == null) return undefined
				return produce(oldData, (draft) => {
					draft.thread_name = newName
				})
			})
		},
		mutationFn: async (variables: {threadId: string; newName: string; projectId: string}) => {
			return await axiosAuthInstance.put<{thread: Thread}>(`/projects/${variables.projectId}/threads/${variables.threadId}`, {
				thread: {
					thread_name: variables.newName,
				},
			})
		},
	})
}

// List of available bots for currently logged in student
export function useBots() {
	const query = useQuery({
		queryKey: ['bots'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<{tutorbots: BotConfig[]}>(`/tutorbots`)
			return data.tutorbots
		},
		staleTime: 1000 * 60 * 60, // 1 hour
	})
	return {bots: query.data, botsIsLoading: query.isLoading}
}

export async function refreshToken() {
	const token = localStorage.getItem('token')
	const jwt = decodeJwt(token)

	const expMs = jwt.payload.exp * 1000
	const iatMs = jwt.payload.iat * 1000
	const timeSinceTokenIssued = Date.now() - iatMs
	const timeTillExpiry = expMs - Date.now()

	// If token is older than 1 hour and not expired
	if (timeSinceTokenIssued > 1000 * 60 * 60 && timeTillExpiry > 0) {
		const refreshResponse = await axios.post<{refresh_token: string}>('/auth/refresh', null, {
			baseURL: API_BASE_URL,
			headers: {Authorization: `Bearer ${token}`},
		})

		if (refreshResponse.status === 200) {
			localStorage.setItem('token', refreshResponse.data.refresh_token)
		}
	}
}

// Profile

export function useProfile() {
	return useQuery({
		queryKey: ['profile'],
		queryFn: async () => {
			const {data} = await axiosAuthInstance.get<Profile>('/user/me')
			return data
		},
	})
}

export function useUpdateProfile() {
	const {data: context} = useApiContext()
	return useMutation({
		mutationFn: async (profile: Profile) => {
			const updatedProfile = produce(profile, (draft) => {
				draft.user.firstName = context?.context.given_name ?? ''
				draft.user.lastName = context?.context.family_name ?? ''
				draft.user.email = context?.context.canvas_user_email_id ?? ''
			})
			const {data} = await axiosAuthInstance.put<Profile>('/user/me', updatedProfile)
			return data
		},
	})
}

export function useHasOnboarded() {
	const {data: profile} = useProfile()
	const [reshowOnboarding] = useAtom(reshowOnboardingAtom)

	if (reshowOnboarding) {
		return false // Force show onboarding if reshowOnboarding is true
	}

	// Otherwise check if they've completed onboarding before
	return profile == null || !!profile.user.appData.find((app) => app.appId === 'ailearningassistant')?.attributes.find((attribute) => attribute.key === 'onboarded')?.value
}

export function useSetHasOnboarded() {
	const {data: profile} = useProfile()
	const {mutate: updateProfile} = useUpdateProfile()
	const [, setReshowOnboarding] = useAtom(reshowOnboardingAtom)

	return () => {
		if (profile == null) return
		const updatedProfile = produce(profile, (draft) => {
			const attributes = draft.user.appData.find((app) => app.appId === 'ailearningassistant')?.attributes
			const onboarded = attributes?.find((attribute) => attribute.key === 'onboarded')
			if (onboarded == null) {
				attributes?.push({
					key: 'onboarded',
					value: true,
				})
			} else {
				onboarded.value = true
			}
		})
		queryClient.setQueryData(['profile'], updatedProfile)
		updateProfile(updatedProfile)
		setReshowOnboarding(false)
	}
}
