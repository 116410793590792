import {QueryClientProvider} from '@tanstack/react-query'
import {createRouter, redirect, RouterProvider} from '@tanstack/react-router'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {queryClient, refreshToken} from '~/api.ts'
import './index.css'
import {routeTree} from './routeTree.gen'
import {setupBookmarklets} from './utils/bookmarklets'
import {decodeJwt} from './utils/utils'

if (window.location.pathname === '/') {
	const searchParams = new URLSearchParams(new URL(window.location.href).search)
	const token = searchParams.get('ltik') ?? localStorage.getItem('token')
	if (token != null && token.length > 0) {
		localStorage.setItem('token', token)
	} else {
		redirect({to: '/no-token', throw: true})
	}

	const jwt = decodeJwt(token)
	const tokenCourseId = jwt.payload.canvasSisCourseId
	const tokenRole = jwt.payload.role
	history.pushState({}, '', tokenRole === 'student' || searchParams.get('preview') === 'true' ? `/course/${tokenCourseId}/chat` : `/course/${tokenCourseId}/access`)
} else if (localStorage.getItem('token') == null) {
	history.pushState({}, '', '/no-token')
}

setInterval(
	() => {
		void refreshToken()
	},
	1000 * 60 * 60, // 1 hour
)

// Create a new router instance
const router = createRouter({routeTree})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

let root = document.getElementById('root')
if (root == null) {
	root = document.createElement('div')
	root.id = 'root'
	document.body.appendChild(root)
}

ReactDOM.createRoot(root).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
		</QueryClientProvider>
		{/*<NetworkErrorHandling />*/}
	</React.StrictMode>,
)

setupBookmarklets()
