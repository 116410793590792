import {axiosAuthInstance} from '~/api'
import {Profile} from '~/model'

export function setupBookmarklets() {
	setupResetOnboardedBookmarklet()
}

declare global {
	interface Window {
		resetOnboardedStatus: () => Promise<void>
	}
}

function setupResetOnboardedBookmarklet() {
	window.resetOnboardedStatus = async () => {
		try {
			const {data: profile} = await axiosAuthInstance.get<Profile>('/user/me')
			const attributes = profile.user.appData.find((app) => app.appId === 'ailearningassistant')?.attributes
			const onboarded = attributes?.find((attribute) => attribute.key === 'onboarded')
			if (onboarded == null) {
				return
			} else {
				onboarded.value = false
			}
			await axiosAuthInstance.put<Profile>('/user/me', profile)
			window.location.reload()
		} catch (error) {
			alert('Failed to reset onboarding status')
			console.error(error)
		}
	}
}
