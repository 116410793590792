import {useAtomValue} from 'jotai/index'
import {AilaPing} from '~/assets/AilaPing.tsx'
import {AilaHeading} from '~/components/AilaHeading.tsx'
import {pendingMessageAtom} from '~/state.ts'

export function PendingMessage() {
	const pendingMessage = useAtomValue(pendingMessageAtom)
	return (
		<>
			<div className="flex flex-col border-t border-uom-grey-dark-25 py-[24px]">
				<div className="whitespace-pre-line break-words text-[18px] leading-[140%]">{pendingMessage}</div>
			</div>
			<div className="flex flex-col border-t border-uom-grey-dark-25 pt-[24px]">
				<AilaHeading className="pb-[18px] pt-[24px]" />
				<AilaPing />
			</div>
		</>
	)
}
