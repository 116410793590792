import {useLocation, useNavigate} from '@tanstack/react-router'
import {clsx} from 'clsx'
import {useAtom} from 'jotai/index'
import {useEffect, useRef, useState} from 'react'
import {MdArrowUpward} from 'react-icons/md'
import {useBotConfig, useChatCompletion} from '~/api.ts'
import {Route} from '~/routes/course.$courseId._student-layout'
import {pendingMessageAtom} from '~/state.ts'

export const PromptInput = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const {courseId} = Route.useParams()
	const botConfig = useBotConfig()

	const {mutateAsync: chatCompletionAsyncApi} = useChatCompletion(location.pathname.includes('revision'))

	const [pendingMessage, setPendingMessage] = useAtom(pendingMessageAtom)

	const [count, setCount] = useState(1) // This is a huge hack to make the textarea clear, new lines were persisting even after value was set to ''
	const [text, setText] = useState('')
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const [moveButtonOutsideInput, setMoveButtonOutsideInput] = useState(false)

	useEffect(() => {
		const textArea = textareaRef.current
		if (textArea) {
			textArea.style.height = 'auto' // Reset height to get the correct scrollHeight
			setMoveButtonOutsideInput(textArea.scrollHeight > 236)
			if (text === '' || textArea.scrollHeight < 62) {
				// When there should only be one row
				textArea.style.height = '48px'
				textArea.style.overflowY = 'hidden'
			} else {
				textArea.style.height = `${Math.min(textArea.scrollHeight + 2, 260)}px`
				textArea.style.overflowY = 'auto'
			}
		}
	}, [text])

	useEffect(() => {
		setText('')
	}, [location.pathname, setText])

	useEffect(() => {
		if (pendingMessage === null) {
			textareaRef.current?.focus()
		}
	}, [pendingMessage])

	const isDisabled = pendingMessage != null || botConfig?.project_id == null

	const sendNewMessage = async (newMessage: string) => {
		if (newMessage.length === 0 || botConfig?.project_id == null) {
			return
		}

		setPendingMessage(newMessage)
		const response = await chatCompletionAsyncApi({message: newMessage})
		setPendingMessage(null)
		void navigate({to: `/course/$courseId/threads/$threadId`, params: {courseId, threadId: response.SK}})
	}

	const send = () => {
		void sendNewMessage(text)
		setText('')
		setCount(count + 1)
		textareaRef.current?.focus()
	}

	return (
		<div className="flex items-center justify-center">
			<div className="flex w-full flex-col items-center justify-center md:max-w-[648px]">
				<div className={clsx(isDisabled && 'opacity-30', 'flex w-full flex-col items-center justify-center')}>
					<div className="hidden items-center gap-x-[4px] self-end pb-[4px] text-[12px] leading-[140%] text-uom-grey-dark-75 md:flex">
						Use <div className="rounded-[2px] bg-uom-blue-light-25 px-[4px] py-[2px] font-[600] leading-[100%] text-uom-heritage-100">shift + return</div> for a new line
					</div>
					<div className="relative w-full">
						<textarea
							key={count}
							ref={textareaRef}
							rows={1}
							className={clsx(
								moveButtonOutsideInput ? 'pr-[16px]' : 'pr-[42px]',
								'h-[48px] w-full grow resize-none overflow-y-auto border border-uom-grey-dark-50 py-[10px] pl-[16px] text-[18px] leading-[150%] text-uom-grey-dark-100 ring ring-transparent ring-offset-0 placeholder:text-uom-grey-dark-75 focus:border-uom-heritage-100 focus:ring-uom-heritage-100',
							)}
							value={text}
							placeholder="Ask anything..."
							onChange={(e) => {
								setText(e.target.value)
							}}
							onKeyDown={(event) => {
								if (event.key === 'Enter' && !event.shiftKey) {
									send()
								}
							}}
							disabled={isDisabled}
						/>
						<div className={clsx(moveButtonOutsideInput ? 'right-[-55px]' : 'right-0', 'absolute inset-y-0 flex items-end pb-[12px] pr-[12px]')}>
							<button
								className="flex size-[24px] items-center justify-center rounded-full bg-uom-blue-light-100 hover:bg-uom-blue-light-25 active:bg-uom-blue-light-75 disabled:pointer-events-none"
								onClick={send}
								disabled={isDisabled}
							>
								<MdArrowUpward
									className="size-[18px] text-uom-heritage-100"
									aria-hidden="true"
								/>
							</button>
						</div>
					</div>
				</div>
				<div className="w-full p-[12px] text-center text-[12px] font-[400] text-uom-grey-dark-75">The AI learning assistant can make mistakes. Double check responses by referencing LMS content.</div>
			</div>
		</div>
	)
}
