import {Link} from '@tanstack/react-router'
import {Route} from '~/routes/course.$courseId'

export const EducatorTabs = () => {
	const {courseId} = Route.useParams()
	return (
		<div className="justify flex shrink-0 items-center gap-[16px] pr-[12px]">
			<Link
				to="/course/$courseId/access"
				params={{courseId}}
				className="border-b-2 border-transparent pb-[2px] text-[16px] font-[600] text-uom-heritage-100 hover:border-uom-heritage-50"
				activeProps={{className: 'border-uom-heritage-100 hover:border-uom-heritage-100'}}
			>
				Access
			</Link>
			<Link
				to="/course/$courseId/lmsResources"
				params={{courseId}}
				className="border-b-2 border-transparent pb-[2px] text-[16px] font-[600] text-uom-heritage-100 hover:border-uom-heritage-50"
				activeProps={{className: 'border-uom-heritage-100 hover:border-uom-heritage-100'}}
			>
				LMS resources
			</Link>
			<Link
				to="/course/$courseId/prompt-enhancements"
				params={{courseId}}
				className="border-b-2 border-transparent pb-[2px] text-[16px] font-[600] text-uom-heritage-100 hover:border-uom-heritage-50"
				activeProps={{className: '!border-uom-heritage-100 hover:border-uom-heritage-100'}}
			>
				Prompt enhancements
			</Link>
		</div>
	)
}
