import {Client} from '@stomp/stompjs'
import {useQueryClient} from '@tanstack/react-query'
import {useEffect} from 'react'
import {API_BASE_URL, useBotConfig} from '~/api'
import {isContinuePollingStatus} from '~/model'
import {decodeJwt} from '~/utils/utils'

interface StatusUpdate {
	sis_course_id: string
	id: number
	status: string
}

export function useStatusUpdateSubscription() {
	const queryClient = useQueryClient()
	const botConfig = useBotConfig()
	const token = localStorage.getItem('token')

	useEffect(() => {
		if (!botConfig) return

		const decodedToken = decodeJwt(token)
		if (decodedToken.payload.exp * 1000 < Date.now()) return

		let client: Client | null = null

		if (isContinuePollingStatus(botConfig.status)) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (client == null) {
				client = new Client({
					brokerURL: `${API_BASE_URL.replace('https://', 'wss://').replace('/api/v1', '')}/websocket/${botConfig.sis_course_id}`,
					connectHeaders: {Authorization: `Bearer ${token}`},
					onStompError: (frame) => {
						console.error('Stomp error: ', frame)
					},
					onWebSocketError: (error) => {
						console.error('WebSocket error: ', error, decodeJwt(token))
					},
					onConnect: () => {
						console.info('Connected to WebSocket')
						client?.subscribe(`/status/${botConfig.sis_course_id}`, (message) => {
							const statusUpdate = JSON.parse(message.body) as StatusUpdate
							console.info('Received status update:', statusUpdate)
							// Invalidate queries
							void queryClient.invalidateQueries({queryKey: ['tutorbot', botConfig.sis_course_id, 'config']})
							void queryClient.invalidateQueries({queryKey: ['dataSet', botConfig.dataset_id]})
							void queryClient.invalidateQueries({queryKey: ['dataSets', botConfig.dataset_id, 'dataSources']})
						})
					},
				})
			}
			client.activate()
		}

		function cleanup() {
			if (client?.active) {
				void client.deactivate()
			}
		}

		window.addEventListener('beforeunload', () => {
			cleanup()
		})

		return () => {
			cleanup()
			window.removeEventListener('beforeunload', cleanup)
		}
	}, [botConfig, queryClient])
}
