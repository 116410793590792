import {Children, ComponentPropsWithoutRef, useMemo} from 'react'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {oneLight} from 'react-syntax-highlighter/dist/esm/styles/prism'
import {Source} from '~/model'

// Create a custom light theme
/* const customLightTheme = {
	...oneLight,
	'pre[class*="language-"]': {
		...(oneLight['pre[class*="language-"]'] as CSSProperties),
		fontSize: '14px',
	},
	'code[class*="language-"]': {
		...(oneLight['code[class*="language-"]'] as CSSProperties),
		fontSize: '14px',
	},
} as SyntaxHighlighterProps['style'] */

export function MarkdownRenderer({content, sources}: {content: string; sources?: Source[]}) {
	// Convert `[1]` to be `[1](https://url)`, which is a valid markdown URL
	const convertedContent = useMemo(() => {
		if (!sources) return content
		return content.replace(/\[(\d+)\](?!\(.*?\))/g, (match, number: string) => {
			const referenceNumber = parseInt(number, 10)
			const index = sources.findIndex((source) => source.referenceNumber === referenceNumber)
			if (index >= 0) {
				return `[${index + 1}](${sources[index].source})`
			}
			return match
		})
	}, [content, sources])

	return (
		<div className="[&>*:first-child]:mt-0 [&>*:last-child]:mb-0">
			<ReactMarkdown
				components={{
					code({className, children, ...props}) {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						const match = /language-(\w+)/.exec(className ?? '')
						const language = match?.[1] ?? undefined
						return match ? (
							<SyntaxHighlighter
								style={oneLight}
								language={language}
								PreTag="div"
								wrapLongLines={true}
							>
								{String(children).replace(/\n$/, '')}
							</SyntaxHighlighter>
						) : (
							<code
								className="bg-light-grey-75 dark:bg-navy-75 whitespace-pre-line break-all rounded px-1 py-0.5 font-mono text-[15px]"
								{...props}
							>
								{children}
							</code>
						)
					},
					// Headings
					h1: ({children}) => <h1 className="md-h1 mb-8 break-words text-4xl font-extrabold tracking-tight">{children}</h1>,
					h2: ({children}) => <h2 className="md-h2 mb-4 mt-8 break-words text-3xl font-semibold">{children}</h2>,
					h3: ({children}) => <h3 className="md-h3 mb-4 mt-6 break-words text-2xl font-semibold">{children}</h3>,
					// Paragraphs
					p: ({children}) => <p className="md-p my-4 break-words leading-7">{children}</p>,
					// Lists
					ul: ({children}) => <ul className="md-ul my-4 ml-6 list-disc break-words">{children}</ul>,
					ol: ({children}) => <ol className="md-ol my-4 ml-6 list-decimal break-words">{children}</ol>,
					// Links
					a: ({children, href, ...props}: ComponentPropsWithoutRef<'a'>) => {
						const childText = Children.toArray(children)[0]
						if (typeof childText === 'string' && /^\d+$/.test(childText)) {
							return (
								<a
									href={href}
									className="relative top-[-2px] inline-flex size-[16px] items-center justify-center rounded-full border border-transparent bg-uom-blue-light-100 text-[12px] font-[600] text-uom-heritage-100 hover:bg-uom-blue-light-25 active:border-uom-heritage-100 active:bg-uom-grey-light-25 [&:not(:first-of-type)]:ml-[4px]"
									target="_blank"
									rel="noopener noreferrer"
									{...props}
								>
									{childText}
								</a>
							)
						}
						return (
							<a
								href={href}
								target="_blank"
								rel="noopener noreferrer"
								className="md-a break-all text-blue-600 underline hover:text-blue-800"
								{...props}
							>
								{children}
							</a>
						)
					},
					// Blockquotes
					blockquote: ({children}) => <blockquote className="md-blockquote break-words border-l-4 border-gray-200 pl-4 italic">{children}</blockquote>,
				}}
			>
				{convertedContent}
			</ReactMarkdown>
		</div>
	)
}
