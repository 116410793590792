import {createFileRoute} from '@tanstack/react-router'
import {useAtom} from 'jotai'
import {useEffect, useRef} from 'react'
import {useBotConfig, useThread} from '~/api.ts'
import {AilaHeading} from '~/components/AilaHeading.tsx'
import {CopyButton} from '~/components/CopyButton.tsx'
import {MarkdownRenderer} from '~/components/MarkdownRenderer'
import {PendingMessage} from '~/components/PendingMessage.tsx'
import {pendingMessageAtom, scrollToBottomOfMessageAtom} from '~/state.ts'
import {messageToClipboardString} from '~/utils/utils'
import {Sources} from '../components/Sources'

export const Route = createFileRoute('/course/$courseId/_student-layout/threads/$threadId')({
	component: Thread,
})

function Thread() {
	const botConfig = useBotConfig()
	const {data: thread} = useThread()
	const [pendingMessage] = useAtom(pendingMessageAtom)
	const [, setScrollToBottomOfMessage] = useAtom(scrollToBottomOfMessageAtom)

	const pendingResponseRef = useRef<HTMLDivElement>(null)
	const endOfMessagesRef = useRef<HTMLDivElement>(null)

	// These two are separate because we want to scroll to the bottom of the thread instant when the messages change, but we want to scroll to the pending message when it changes
	useEffect(() => {
		setScrollToBottomOfMessage((prev) => prev + 1)
	}, [thread, setScrollToBottomOfMessage])

	useEffect(() => {
		pendingResponseRef.current?.scrollIntoView({behavior: 'smooth'})
		endOfMessagesRef.current?.scrollIntoView({behavior: 'smooth'})
	}, [pendingMessage])

	const socratic = thread?.prompt_template_id === 'SOCRATIC'
	const messages = socratic ? thread.ui_messages.slice(1) : thread?.ui_messages

	return (
		<>
			<AilaHeading />
			<div className="mb-[24px] mt-[12px] text-[18px] leading-[140%]">
				{socratic ? (
					<div className="max-w-max rounded-[2px] bg-uom-yellow-25 px-[8px] py-[2px] text-[14px] leading-[20px] text-uom-heritage-100">{thread.ui_messages[0].content}</div>
				) : (
					<div>
						Ask me anything about <span className="font-[600]">{botConfig?.tutor_bot_course_name.split('(')[0]?.trim()}</span> ({botConfig?.sis_course_id})
					</div>
				)}
			</div>
			{messages?.map((message, index) => (
				<div
					key={index}
					className="flex flex-col gap-y-[24px] border-t border-uom-grey-dark-25 py-[24px]"
				>
					{message.sources?.length != null && message.sources.length > 0 && <Sources sources={message.sources} />}
					<div className="flex flex-col">
						{message.role === 'assistant' && !(socratic && index === 0) && <AilaHeading className="pb-[12px]" />}
						<div className="whitespace-pre-line break-words text-[18px]">
							<MarkdownRenderer
								content={message.content}
								sources={message.sources}
							/>
						</div>
						{message.role === 'assistant' && (
							<div className="max-w-max">
								<CopyButton
									text={messageToClipboardString(message)}
									tooltip="Copy response"
								/>
							</div>
						)}
					</div>
				</div>
			))}
			{pendingMessage != null && <PendingMessage />}
			<div ref={endOfMessagesRef} />
		</>
	)
}
