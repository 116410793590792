import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useAtomValue} from 'jotai'
import {MdOutlineEmojiObjects, MdRefresh} from 'react-icons/md'
import {useChatCompletion, useSubjectTopics} from '~/api.ts'
import {AilaPing} from '~/assets/AilaPing'
import {SparklesIcon} from '~/assets/SparklesIcon.tsx'
import {AilaHeading} from '~/components/AilaHeading.tsx'
import {pendingMessageAtom} from '~/state'

export const Route = createFileRoute('/course/$courseId/_student-layout/revision')({
	component: () => <Revision />,
})

const Revision = () => {
	const {courseId} = Route.useParams()
	const {data: topics, isFetching: topicsIsLoading, refetch: refetchTopics, isRefetching: topicsIsRefetching} = useSubjectTopics()
	const {mutateAsync: chatCompletion} = useChatCompletion(true)
	const navigate = useNavigate()
	const pendingMessage = useAtomValue(pendingMessageAtom)

	async function createChat(topic: string) {
		const newChat = await chatCompletion({message: topic})
		void navigate({
			to: '/course/$courseId/threads/$threadId',
			params: {courseId: courseId, threadId: newChat.SK},
		})
	}

	if (pendingMessage)
		return (
			<>
				<AilaHeading />
				<div className="mb-[24px] border-b border-uom-grey-dark-25 py-[12px]">
					<div className="max-w-max rounded-[2px] bg-uom-yellow-25 px-[8px] py-[2px] text-[14px] leading-[20px] text-uom-heritage-100">{pendingMessage}</div>
				</div>
				<AilaPing />
			</>
		)

	return (
		<>
			<div className="flex items-center gap-x-[10px] border border-uom-grey-light-50 p-[12px]">
				<div className="flex size-[32px] shrink-0 items-center justify-center bg-uom-yellow-100">
					<MdOutlineEmojiObjects className="size-[20px] shrink-0 text-uom-heritage-100" />
				</div>

				<p className="leading-[120%]">
					<span className="font-[700]">Revision: </span>
					Reinforce your knowledge with Aila’s guidance. Referencing Canvas content, Aila acts as a tutor, asking targeted questions within a topic to deepen your understanding.
				</p>
			</div>
			<div className="mt-[64px] flex flex-col gap-y-[12px] text-[24px] font-[600] leading-[120%]">
				<p>Hello, I’m Aila. 👋</p>
				<p>I have generated topics from the curriculum available in Canvas.</p>
				<p>Select a topic and I will ask you about it.</p>
			</div>
			<h3 className="mt-[24px] font-[600] leading-[120%]">Subject topics</h3>
			{topicsIsRefetching && (
				<div className="mt-[24px] flex flex-wrap items-center gap-x-[8px] text-uom-heritage-100">
					<SparklesIcon className="h-[22px] animate-spin pl-[4px]" />
					Generating new topics from Canvas...
				</div>
			)}
			{!topicsIsLoading && topics && (
				<>
					<div className="mt-[8px] flex flex-wrap gap-[8px]">
						{topics
							.map((topic) => topic.content)
							.map((topic, index) => (
								<button
									onClick={() => void createChat(topic)}
									key={index}
									className="flex items-center border border-uom-grey-dark-25 p-[8px] text-[14px] text-uom-heritage-100 hover:bg-uom-blue-light-25 active:border-uom-heritage-100 active:bg-uom-grey-light-25"
								>
									{topic}
								</button>
							))}
					</div>
					<button
						onClick={() => {
							void refetchTopics()
						}}
						className="mt-[12px] flex h-[32px] w-full max-w-max items-center gap-x-[10px] self-end border border-uom-heritage-100 p-3 px-[12px] text-[14px] font-[700] leading-none text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25"
					>
						<MdRefresh className="size-[14px]" /> Generate new topics
					</button>
				</>
			)}
		</>
	)
}
