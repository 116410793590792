import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react'
import {type PropsWithChildren, useRef} from 'react'

interface Props {
	title: string
	open: boolean
	onClose: () => void
}

export const ErrorModal = ({open, onClose, title, children}: PropsWithChildren<Props>) => {
	const cancelButtonRef = useRef(null)

	return (
		<Dialog
			as="div"
			className="relative z-10"
			initialFocus={cancelButtonRef}
			onClose={onClose}
			open={open}
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-black/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
			/>

			<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<DialogPanel className="relative w-full max-w-[500px] transform overflow-hidden bg-white text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
						<div className="flex flex-col p-[24px]">
							<DialogTitle className="font-lead text-[24px] leading-[120%] text-uom-heritage-100">{title}</DialogTitle>
							<div className="mt-[8px] text-[18px] leading-[140%]">{children}</div>
						</div>

						<div className="flex items-center justify-end gap-x-[8px] bg-uom-grey-light-25 px-[12px] py-[8px] text-[14px] font-[700] text-uom-heritage-100">
							<button
								type="button"
								className="h-[32px] border border-uom-blue-light-100 bg-uom-blue-light-100 px-[12px] hover:border-uom-heritage-100 hover:bg-uom-blue-light-25 active:border-uom-heritage-100 active:bg-uom-blue-light-75"
								onClick={onClose}
								ref={cancelButtonRef}
							>
								Okay
							</button>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	)
}
