import {Link, useParams} from '@tanstack/react-router'
import clsx from 'clsx'
import {useHasOnboarded, useThread} from '~/api'
import {Route} from '~/routes/course.$courseId._student-layout'

export function ThreadsTabs() {
	const {courseId} = Route.useParams()
	const {threadId} = useParams({strict: false})
	const {data: thread} = useThread()
	const hasOnboarded = useHasOnboarded()

	return (
		<div className="flex h-[40px] items-center gap-[16px] border-b border-uom-grey-dark-25 bg-uom-grey-light-25 px-[16px] md:px-[24px]">
			<div
				id=""
				className="mx-auto flex h-full w-full max-w-[724px]"
			>
				<div
					id="thread-tabs"
					className={clsx('spotlightable relative flex h-full items-center gap-x-[16px] bg-uom-grey-light-25', !hasOnboarded && 'px-2')}
				>
					<Link
						to="/course/$courseId/chat"
						params={{courseId}}
						className={clsx(
							'flex max-h-max items-center justify-center border-b-2 border-transparent text-uom-heritage-100 [&.active]:border-uom-heritage-100 [&[aria-disabled="true"]]:border-uom-heritage-100',
						)}
						disabled={threadId != null && thread != null && thread.prompt_template_id !== 'SOCRATIC'}
					>
						Chat
					</Link>
					<Link
						to="/course/$courseId/revision"
						params={{courseId}}
						className={clsx(
							'flex max-h-max items-center justify-center border-b-2 border-transparent text-uom-heritage-100 [&.active]:border-uom-heritage-100 [&[aria-disabled="true"]]:border-uom-heritage-100',
						)}
						disabled={threadId != null && thread != null && thread.prompt_template_id === 'SOCRATIC'}
					>
						Revision
					</Link>
				</div>
			</div>
		</div>
	)
}
