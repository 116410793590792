import {PropsWithChildren} from 'react'
import {MdAccessTime, MdConstruction, MdLock, MdOutlineTimer} from 'react-icons/md'
import {useBotConfig} from '~/api.ts'
import {useJwt} from '~/hooks/useJwt.ts'

import {cn, msToHoursMinutes} from '~/utils/utils'

export const EducatorSiteDisabled = ({children}: PropsWithChildren) => {
	const botConfig = useBotConfig()
	const jwt = useJwt()

	if (jwt.payload.exp < Date.now() / 1000) {
		return <SessionExpired />
	}

	if (botConfig?.locked && !botConfig.is_locked_by_current_user) {
		return <LockedOut />
	}

	return children
}

export const StudentSiteDisabled = ({children}: PropsWithChildren) => {
	const botConfig = useBotConfig()
	const jwt = useJwt()

	if (jwt.payload.exp < Date.now() / 1000) {
		return <SessionExpired className="mx-auto mt-[128px] max-w-[680px] px-[16px] md:px-[24px]" />
	}
	if (botConfig == null) return children

	if ((!botConfig.enabled && jwt.payload.role === 'student') || botConfig.status !== 'Ready') {
		return <Maintenance />
	}

	return children
}

function Maintenance() {
	return (
		<div className="mt-[128px] flex w-full justify-center px-[24px]">
			<div className="flex w-full max-w-[632px] flex-col items-center border border-uom-grey-dark-25 p-[24px]">
				<div className="flex bg-uom-blue-light-25 p-[12px]">
					<MdConstruction className="size-[32px]" />
				</div>
				<h3 className="mt-[12px] text-center font-lead text-[30px] leading-[110%]">Down for maintenance</h3>
				<p className="pt-[8px] text-center text-[18px] leading-[140%]">
					The learning assistant for this subject is currently undergoing maintenance.
					<br /> Other subjects may still be accessible.
				</p>
			</div>
		</div>
	)
}

function LockedOut() {
	const botConfig = useBotConfig()

	if (botConfig == null) return null

	return (
		<div className="mt-[48px] flex flex-col items-center border border-uom-grey-dark-25 p-[24px]">
			<div className="flex bg-uom-blue-light-25 p-[12px]">
				<MdLock className="size-[32px]" />
			</div>
			<h3 className="mt-[12px] text-center font-lead text-[30px] leading-[110%]">Locked out for editing</h3>
			<p className="pt-[8px] text-center text-[18px] leading-[140%]">{botConfig.locked_by_user_name} has locked you out to edit the LMS resources or prompt enhancement.</p>
			<p className="pt-[24px] text-center text-[18px] leading-[140%]">This process prevents simultaneous editing by admins.</p>
			<div className="mt-[24px] flex max-w-max items-center gap-x-[4px] rounded-[2px] bg-uom-blue-light-25 px-[8px] py-[4px] text-uom-heritage-100">
				<MdAccessTime className="size-[16px] shrink-0" />
				<div>
					If not unlocked, access will auto-unlock in <span className="font-[700]">{botConfig.autoUnlockAtMs && msToHoursMinutes(botConfig.autoUnlockAtMs - Date.now())}</span>
				</div>
			</div>
		</div>
	)
}

function SessionExpired({className}: {className?: string}) {
	const jwt = useJwt()

	return (
		<div className={cn('mt-[48px]', className)}>
			<div className="flex flex-col items-center border border-uom-grey-dark-25 p-[24px]">
				<div className="flex bg-uom-blue-light-25 p-[12px]">
					<MdOutlineTimer className="size-[32px]" />
				</div>
				<h3 className="mt-[12px] text-center font-lead text-[30px] leading-[110%]">Session expired</h3>
				<p className="pt-[8px] text-center text-[18px] leading-[140%]">Your session has expired. Please return to the LMS and sign in again.</p>
				<div className="mt-[24px] flex gap-x-[8px]">
					<a
						href={`${jwt.payload.canvasBaseUrl}/courses/${jwt.payload.canvasCourseId}`}
						className="flex h-[32px] max-w-max items-center rounded-[2px] bg-uom-blue-light-100 px-[12px] text-[14px] font-[700] text-uom-heritage-100 hover:bg-uom-blue-light-25"
					>
						LMS
					</a>
					<a
						href={`${jwt.payload.canvasBaseUrl}/courses/${jwt.payload.canvasCourseId}/external_tools/${jwt.payload.canvasExternalToolId}?display=borderless`}
						className="flex h-[32px] max-w-max items-center rounded-[2px] bg-uom-blue-light-100 px-[12px] text-[14px] font-[700] text-uom-heritage-100 hover:bg-uom-blue-light-25"
					>
						Reload
					</a>
				</div>
			</div>
		</div>
	)
}
