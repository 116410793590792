import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react'
import useEmblaCarousel from 'embla-carousel-react'
import {MdArrowDropDown, MdEast, MdOutbond, MdWest} from 'react-icons/md'
import {Tooltip, TooltipContent, TooltipTrigger} from '~/components/Tooltip'
import {Source} from '~/model'

export function Sources({sources}: {sources: Source[]}) {
	const [emblaRef, emblaApi] = useEmblaCarousel({loop: false})

	return (
		<Disclosure
			as="div"
			className="border border-uom-grey-dark-25"
		>
			<DisclosureButton className="group flex w-full items-center justify-between p-[8px] data-[headlessui-state=hover]:bg-uom-grey-light-25">
				<span className="text-[14px] font-[600] text-uom-heritage-100">Canvas sources</span>
				<div className="flex items-center gap-x-[24px]">
					<div className="flex">
						{sources.map((_, index) => (
							<div
								key={index}
								className="ml-[-5px] flex size-[20px] items-center justify-center rounded-full border-2 border-uom-blue-light-75 bg-uom-blue-light-100 text-[14px] font-[600]"
							>
								{index + 1}
							</div>
						))}
					</div>
					<div className="flex size-[20px] items-center rounded-full bg-uom-grey-light-25 group-data-[headlessui-state=hover]:bg-white">
						<MdArrowDropDown className="size-[20px] fill-uom-heritage-100 transition-transform duration-150 group-data-[open]:rotate-180" />
					</div>
				</div>
			</DisclosureButton>
			<DisclosurePanel className="pb-[8px] transition-transform duration-150">
				<div
					className="overflow-hidden"
					ref={emblaRef}
				>
					<div className="ml-[8px] mr-[-8px] flex touch-pan-y touch-pinch-zoom gap-[8px]">
						{sources.map((source, index) => (
							<Tooltip
								key={source.uri}
								placement="bottom"
							>
								<TooltipTrigger className="group min-w-[230px] max-w-[260px] shrink-0 cursor-pointer gap-y-[5px] border border-uom-grey-dark-25 p-[8px] last:mr-[20px] hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25">
									<a
										href={source.source}
										target="_blank"
										rel="noreferrer"
										className="embla__slide flex h-full min-w-[230px] max-w-[260px] cursor-pointer justify-between gap-x-[8px]"
									>
										<div className="flex flex-col justify-between gap-y-[13px]">
											<div
												className="line-clamp-2 overflow-ellipsis break-all text-[14px] leading-[120%] text-uom-heritage-100 group-hover:underline"
												dangerouslySetInnerHTML={{
													__html: source.title.replaceAll('_', '_<wbr>'),
												}}
											/>
											<div className="text-[14px] leading-[120%] text-uom-grey-dark-100">canvas.lms.unimelb.edu.au</div>
										</div>
										<div className="flex w-[16px] shrink-0 flex-col items-end justify-between gap-x-[4px]">
											<div className="square ml-[-5px] flex h-[16px] w-full shrink-0 items-center justify-center rounded-full bg-uom-blue-light-100 text-[12px] font-[600]">{index + 1}</div>
											<MdOutbond className="size-[17px] shrink-0 text-uom-heritage-100" />
										</div>
									</a>
								</TooltipTrigger>
								<TooltipContent className="mt-0 bg-uom-blue-dark-100 px-[8px] py-[4px] text-white">Open in a new tab</TooltipContent>
							</Tooltip>
						))}
					</div>
				</div>
				<div className="flex items-center gap-[4px] px-[8px] pt-[8px]">
					<button
						onClick={() => emblaApi?.scrollPrev()}
						className="flex size-[20px] shrink-0 items-center justify-center rounded-full border border-uom-grey-dark-25 hover:bg-uom-blue-light-25 active:border-uom-heritage-100 active:bg-uom-grey-light-25"
					>
						<MdWest className="size-[12px] shrink-0 fill-uom-heritage-100" />
					</button>
					<button
						onClick={() => emblaApi?.scrollNext()}
						className="flex size-[20px] shrink-0 items-center justify-center rounded-full border border-uom-grey-dark-25 hover:bg-uom-blue-light-25 active:border-uom-heritage-100 active:bg-uom-grey-light-25"
					>
						<MdEast className="size-[12px] shrink-0 fill-uom-heritage-100" />
					</button>
				</div>
			</DisclosurePanel>
		</Disclosure>
	)
}
