import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react'
import {useNavigate, useParams} from '@tanstack/react-router'
import {useEffect, useRef, useState} from 'react'
import {useSetHasOnboarded} from '~/api'
import {Route} from '~/routes/course.$courseId'
import {cn} from '~/utils/utils'

interface Step {
	title: string
	content: string[]
	spotlightElementId?: string
	navigateTo?: 'new' | 'chat' | 'revision' | 'original'
	position?: 'right' | 'bottom'
}

const steps: Step[] = [
	{
		title: 'Welcome to Aila! 👋',
		content: ['Aila is the University of Melbourne’s AI learning assistant.', 'Let’s do a super quick walk through.'],
	},
	{
		title: 'Subject switcher',
		content: ['If you have access to Aila across multiple subjects, you can switch between them using the subject switcher.'],
		spotlightElementId: 'course-switcher',
		navigateTo: 'new',
		position: 'right',
	},
	{
		title: 'Threads',
		content: ['Within each subject, you can create many threads. Each thread is a separate conversation with Aila that you can manage and refer back to if you need.'],
		spotlightElementId: 'threads-list',
		position: 'right',
	},
	{
		title: 'Chat mode',
		content: ['Ask subject-related questions. Aila works like ChatGPT but only references content within Canvas—not the internet.'],
		spotlightElementId: 'thread-tabs',
		navigateTo: 'chat',
		position: 'bottom',
	},
	{
		title: 'Revision mode',
		content: ['Reinforce your knowledge with Aila’s guidance. Referencing Canvas content, Aila acts as a tutor, asking targeted questions within a topic to deepen your understanding.'],
		spotlightElementId: 'thread-tabs',
		navigateTo: 'revision',
		position: 'bottom',
	},
	{
		title: 'Canvas (UniMelb LMS)',
		content: ['Aila is specifically designed to reference content within Canvas (UniMelb LMS) that your educators have selected. It is not designed to reference information on the internet.'],
	},
	{
		title: 'Aila can make mistakes',
		content: [
			'The AI learning assistant (Aila) can make mistakes. Double-check its responses against the content in Canvas.',
			'Refer to Canvas sources within each AI response to verify where Aila retrieved the information.',
		],
		navigateTo: 'original',
	},
]

export const OnboardingWizard = () => {
	const [currentStepIndex, setCurrentStepIndex] = useState(0)
	const currentStep = steps[currentStepIndex]
	const nextRef = useRef<HTMLButtonElement>(null)
	const setHasOnboarded = useSetHasOnboarded()
	const navigate = useNavigate()
	const {courseId} = Route.useParams()
	const {threadId} = useParams({strict: false})
	const originalThreadIdRef = useRef(threadId)
	const modalRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		unspotlightAll()

		if (currentStep.navigateTo) {
			switch (currentStep.navigateTo) {
				case 'new':
					void navigate({to: '/course/$courseId/threads/$threadId', params: {courseId, threadId: 'new'}})
					break
				case 'chat':
					void navigate({to: '/course/$courseId/chat', params: {courseId}})
					break
				case 'revision':
					void navigate({to: '/course/$courseId/revision', params: {courseId}})
					break
				case 'original':
					if (originalThreadIdRef.current) {
						void navigate({to: '/course/$courseId/threads/$threadId', params: {courseId, threadId: originalThreadIdRef.current}})
					} else {
						void navigate({to: '/course/$courseId/chat', params: {courseId}})
					}
					break
			}
		}

		if (currentStep.spotlightElementId) {
			const element = document.getElementById(currentStep.spotlightElementId)

			if (element) {
				element.style.zIndex = '51'
				if (!modalRef.current) return
				if (currentStep.position === 'right') {
					modalRef.current.style.left = (element.getBoundingClientRect().right + 12).toString() + 'px'
					modalRef.current.style.top = element.getBoundingClientRect().top.toString() + 'px'
				} else if (currentStep.position === 'bottom') {
					modalRef.current.style.left = element.getBoundingClientRect().left.toString() + 'px'
					modalRef.current.style.top = (element.getBoundingClientRect().bottom + 12).toString() + 'px'
				}
			}
		} else {
			if (!modalRef.current) return
			modalRef.current.style.left = ''
			modalRef.current.style.top = ''
		}
	}, [currentStep])

	const handleNext = () => {
		if (currentStepIndex === steps.length - 1) {
			handleComplete()
		} else {
			setCurrentStepIndex((prev) => prev + 1)
		}
	}

	const handlePrevious = () => {
		setCurrentStepIndex((prev) => prev - 1)
	}

	const handleComplete = () => {
		unspotlightAll()
		setHasOnboarded()
	}

	const unspotlightAll = () => {
		const spotlightable = document.querySelectorAll<HTMLElement>('.spotlightable')
		spotlightable.forEach((element) => {
			element.style.zIndex = 'unset'
		})
	}

	return (
		<Dialog
			as="div"
			className="relative z-50"
			initialFocus={nextRef}
			open={true}
			onClose={() => {
				return
			}}
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 z-50 bg-black/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
			/>

			<div className="fixed inset-0 z-[52] w-screen overflow-y-auto">
				<div className={cn('min-h-full', currentStep.position == null ? 'flex justify-center p-4 text-center sm:items-center sm:p-0' : '')}>
					<DialogPanel
						ref={modalRef}
						className="relative w-full max-w-[500px] transform overflow-hidden bg-white text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
					>
						<div className="flex flex-col p-[24px]">
							<DialogTitle className="font-lead text-[24px] leading-[120%] text-uom-heritage-100">{currentStep.title}</DialogTitle>
							<div className="mt-[8px] space-y-[1rem] text-[18px] leading-[140%]">
								{currentStep.content.map((content, index) => (
									<p key={index}>{content}</p>
								))}
							</div>
						</div>
						<div className="flex items-center justify-between bg-uom-grey-light-25 px-[12px] py-[8px]">
							<div className="text-[18px] text-uom-grey-dark-100">
								{currentStepIndex + 1} / {steps.length}
							</div>
							<div className="flex items-center gap-x-[8px]">
								<button
									className="h-[32px] border border-uom-heritage-100 px-[12px] text-uom-heritage-100 hover:enabled:bg-uom-grey-light-50 active:enabled:bg-uom-grey-light-75 disabled:cursor-not-allowed disabled:opacity-50"
									onClick={handlePrevious}
									disabled={currentStepIndex === 0}
								>
									Back
								</button>
								<button
									ref={nextRef}
									className="h-[32px] border border-transparent bg-uom-blue-light-100 px-[12px] text-uom-heritage-100 hover:border-uom-heritage-100 hover:bg-uom-blue-light-25 active:border-uom-heritage-100 active:bg-uom-blue-light-75"
									onClick={handleNext}
								>
									{currentStepIndex === steps.length - 1 ? 'Done' : 'Next'}
								</button>
							</div>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	)
}
