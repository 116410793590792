import {createFileRoute, Outlet} from '@tanstack/react-router'
import {EducatorSubNavbar} from '~/components/EducatorSubNavbar.tsx'
import {useStatusUpdateSubscription} from '~/hooks/useStatusUpdateSubscription.ts'

export const Route = createFileRoute('/course/$courseId/_educator-layout')({
	component: EducatorLayout,
})

function EducatorLayout() {
	useStatusUpdateSubscription()
	return (
		<div className="flex h-full flex-col">
			<EducatorSubNavbar />
			<div className="grow overflow-y-auto px-[32px] py-[24px] lg:px-[80px]">
				<Outlet />
			</div>
		</div>
	)
}
