import {createFileRoute, Outlet, redirect} from '@tanstack/react-router'
import {isStudent} from '~/utils/utils'

const basePathRegex = /^\/course\/[^/]+\/?$/
const threadsChatRegex = /^\/course\/[^/]+\/threads\/(chat\/?)?$/
const threadsRevisionRegex = /^\/course\/[^/]+\/threads\/(revision|socratic-tutor)\/?$/

export const Route = createFileRoute('/course/$courseId')({
	component: Outlet,
	beforeLoad: ({params}) => {
		const {pathname} = window.location
		const {courseId} = params

		// Handle base course path
		if (basePathRegex.test(pathname)) {
			redirect({
				to: isStudent() ? `/course/${courseId}/chat` : `/course/${courseId}/access`,
				throw: true,
			})
		}

		// Handle threads/chat redirects
		if (threadsChatRegex.test(pathname)) {
			redirect({
				to: `/course/${courseId}/chat`,
				throw: true,
			})
		}

		// Handle revision/socratic-tutor redirects
		if (threadsRevisionRegex.test(pathname)) {
			redirect({
				to: `/course/${courseId}/revision`,
				throw: true,
			})
		}
	},
})
