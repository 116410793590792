import {createRootRoute, Outlet} from '@tanstack/react-router'
import {Navbar} from '~/components/Navbar.tsx'
import {WindowsSizeIndicator} from '~/components/WindowsSizeIndicator.tsx'

export const Route = createRootRoute({
	component: Root,
})

function Root() {
	if (window.location.pathname === '/no-token') return <Outlet />

	return (
		<div className="flex h-full flex-col">
			<Navbar />
			<Outlet />
			{import.meta.env.DEV && <WindowsSizeIndicator />}
		</div>
	)
}
