import {createFileRoute} from '@tanstack/react-router'
import {useAtomValue} from 'jotai'
import {MdOutlineEmojiObjects} from 'react-icons/md'
import {useBotConfig} from '~/api.ts'
import {AilaHeading} from '~/components/AilaHeading.tsx'
import {PendingMessage} from '~/components/PendingMessage.tsx'
import {pendingMessageAtom} from '~/state'

export const Route = createFileRoute('/course/$courseId/_student-layout/chat')({
	component: NewChat,
})

function NewChat() {
	const botConfig = useBotConfig()
	const pendingMessage = useAtomValue(pendingMessageAtom)

	if (pendingMessage)
		return (
			<>
				<AilaHeading />
				<div className="mt-[12px] pb-[24px] text-[18px] leading-[140%]">
					Ask me anything about <span className="font-[600]">{botConfig?.tutor_bot_course_name.split('(')[0]?.trim()}</span> ({botConfig?.sis_course_id})
				</div>
				<PendingMessage />
			</>
		)

	return (
		<>
			<div className="flex items-center gap-x-[10px] border border-uom-grey-light-50 p-[12px]">
				<div className="flex size-[32px] shrink-0 items-center justify-center bg-uom-yellow-100">
					<MdOutlineEmojiObjects className="size-[20px] shrink-0 text-uom-heritage-100" />
				</div>

				<p className="leading-[120%]">
					<span className="font-[700]">Chat: </span>
					Ask subject-related questions. Aila works like ChatGPT but only references content within Canvas—not the internet.
				</p>
			</div>
			<div className="mt-[64px] flex flex-col gap-y-[12px] text-[24px] font-[600] leading-[120%]">
				<p>Hello, I’m Aila. 👋</p>
				<p>I have access to the curriculum in Canvas.</p>
				{botConfig && <p>Ask anything about {botConfig.tutor_bot_course_name}. </p>}
			</div>
		</>
	)
}
