import {Dialog, DialogBackdrop, DialogPanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {Link, useLocation} from '@tanstack/react-router'
import {clsx} from 'clsx'
import {useAtom} from 'jotai'
import {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {HiEllipsisVertical} from 'react-icons/hi2'
import {MdAdd, MdCheck, MdClose} from 'react-icons/md'
import {useMediaQuery} from 'usehooks-ts'
import {queryClient, useApiContext, useBotConfig, useHasOnboarded, useRenameThread, useThread, useThreads} from '~/api.ts'
import {ImpactIcon} from '~/assets/ImpactIcon.tsx'
import {Tooltip, TooltipContent, TooltipTrigger} from '~/components/Tooltip.tsx'
import {UniqueNameRequiredModal} from '~/components/UniqueNameRequiredModal.tsx'
import {type Thread, type ThreadSummery} from '~/model.ts'
import {Route} from '~/routes/course.$courseId._student-layout'
import {deleteThreadIdAtom, isShowMobileMenuAtom, pendingMessageAtom, renameThreadIdAtom} from '~/state.ts'
import {copyThreadToClipboard} from '~/utils/utils'
import {CourseSwitcher} from './CourseSwitcher'

export function ThreadsLeftSidebar() {
	const [isShowMobileMenu, setIsShowMobileMenu] = useAtom(isShowMobileMenuAtom)

	return (
		<>
			{/* Desktop */}
			<div className="hidden h-full min-h-full w-[240px] flex-shrink-0 flex-col justify-between border-r border-uom-grey-dark-25 bg-uom-grey-light-25 md:flex">
				<ThreadsLeftSidebarContent />
			</div>

			{/* Mobile */}
			<div className="md:hidden">
				<Dialog
					open={isShowMobileMenu}
					onClose={setIsShowMobileMenu}
					className="relative z-10 md:hidden"
				>
					<DialogBackdrop
						transition
						className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
					/>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
								<DialogPanel
									transition
									className="pointer-events-auto w-screen max-w-[374px] transform transition duration-500 ease-in-out data-[closed]:-translate-x-full sm:duration-700"
								>
									<div className="flex h-full flex-col bg-white">
										<div className="flex shrink-0 items-center justify-end bg-uom-heritage-100 px-[16px] py-[12px]">
											<button
												type="button"
												onClick={() => {
													setIsShowMobileMenu(false)
												}}
												className="p-[8px] text-white"
											>
												<span className="sr-only">Close panel</span>
												<MdClose
													className="size-[24px]"
													aria-hidden="true"
												/>
											</button>
										</div>
										<ThreadsLeftSidebarContent />
									</div>
								</DialogPanel>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		</>
	)
}

export const ThreadsLeftSidebarContent = () => {
	const location = useLocation()
	const {courseId} = Route.useParams()
	const [, setIsShowMobileMenu] = useAtom(isShowMobileMenuAtom)

	const {data: context} = useApiContext()
	const botConfig = useBotConfig()
	const {data: thread} = useThread()
	const {data: threads} = useThreads()
	const {mutate: mutateRenameThread} = useRenameThread()
	const hasOnboarded = useHasOnboarded()

	const [renameThreadId, setRenameThreadId] = useAtom(renameThreadIdAtom)
	const [, setDeleteThreadId] = useAtom(deleteThreadIdAtom)
	const [, setPendingMessage] = useAtom(pendingMessageAtom)

	const [renameThreadValue, setRenameThreadValue] = useState<string | null>(null)
	const [uniqueNameRequiredType, setUniqueNameRequiredType] = useState<string | null>(null)
	const [threadListScrollHeight, setThreadListScrollHeight] = useState<number>(0)

	const threadListScrollRef = useRef<HTMLDivElement>(null)
	const renameThreadInputRef = useRef<HTMLInputElement>(null)
	const userDetailsContainerRef = useRef<HTMLDivElement>(null)
	const userDetailsNameRef = useRef<HTMLDivElement>(null)
	const userDetailsEmailRef = useRef<HTMLDivElement>(null)

	const [isOverflowed, setIsOverflowed] = useState(false)

	useLayoutEffect(() => {
		if (!userDetailsContainerRef.current || !userDetailsNameRef.current || !userDetailsEmailRef.current) return
		setIsOverflowed(userDetailsNameRef.current.clientWidth >= userDetailsContainerRef.current.clientWidth || userDetailsEmailRef.current.clientWidth >= userDetailsContainerRef.current.clientWidth)
	}, [userDetailsContainerRef, userDetailsNameRef, userDetailsEmailRef, context?.context.user_name, context?.context.canvas_user_email_id])

	const isMobile = useMediaQuery('(min-width: 768px)')

	useEffect(() => {
		if (renameThreadId != null && threads != null) {
			const thread = threads.find((thread) => thread.SK === renameThreadId)
			setRenameThreadValue(thread?.thread_name ?? null)
			setTimeout(() => renameThreadInputRef.current?.select(), 10)
		}
	}, [threads, renameThreadId])

	const renameThread = () => {
		if (renameThreadId == null || renameThreadValue == null || renameThreadValue.length <= 0 || botConfig == null) return

		if (threads?.some((thread) => thread.thread_name === renameThreadValue && thread.SK !== renameThreadId)) {
			setUniqueNameRequiredType('thread')
			return
		}

		if (botConfig.project_id == null) return
		mutateRenameThread({projectId: botConfig.project_id, threadId: renameThreadId, newName: renameThreadValue})
		setRenameThreadValue(null)
		setRenameThreadId(null)
	}

	const noToken = localStorage.getItem('token') == null

	const threadsGroupedByDate = groupThreadsByDate(threads ?? [])

	return (
		<>
			<CourseSwitcher />
			<div className="flex grow flex-col">
				<div className="shrink-0 p-[16px] md:p-[12px]">
					<div
						id="threads-list"
						className={clsx('spotlightable relative bg-uom-grey-light-25', !hasOnboarded ? 'p-[2px]' : '')}
					>
						<Link
							to={thread?.prompt_template_id === 'SOCRATIC' ? '/course/$courseId/revision' : '/course/$courseId/chat'}
							params={{courseId}}
							onClick={() => {
								setPendingMessage(null)
								setIsShowMobileMenu(false)
							}}
							disabled={noToken || location.pathname.includes('revision')}
							className="flex w-full shrink-0 items-center gap-x-[8px] bg-uom-blue-light-100 p-[12px] text-[18px] font-[700] leading-4 text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-blue-light-75 aria-disabled:cursor-not-allowed aria-disabled:bg-uom-blue-dark-50 aria-disabled:text-uom-blue-dark-100 data-[status=active]:cursor-not-allowed data-[status=active]:bg-uom-blue-dark-50 data-[status=active]:text-uom-blue-dark-100"
						>
							<MdAdd className="size-[20px]" />
							<div>New thread</div>
						</Link>
						{!hasOnboarded && (
							<div className="flex shrink-0 flex-col gap-y-[4px] pt-[16px]">
								<div className="text-uom-gray-dark-75 text-[12px]">Today</div>
								<div className="flex h-[40px] shrink-0 items-center border-2 border-transparent border-uom-heritage-100 bg-white px-[8px] text-left text-[16px] font-[600] text-uom-heritage-100">
									Ask anything...
								</div>
							</div>
						)}
					</div>
				</div>
				{hasOnboarded && (
					<div
						ref={threadListScrollRef}
						className="skinny-scrollbar flex flex-col gap-y-[4px] overflow-y-auto p-[16px] pb-[8px] md:p-[12px]"
					>
						{Object.keys(threadsGroupedByDate).map((dateGroup) => (
							<div
								key={dateGroup}
								className="flex shrink-0 flex-col gap-y-[4px]"
							>
								<div className="text-uom-gray-dark-75 text-[12px]">{dateGroup.replaceAll('_', ' ')}</div>
								<div className="flex flex-col gap-y-[4px]">
									{threadsGroupedByDate[dateGroup]?.map((thread) => (
										<div
											key={thread.SK}
											className={clsx(
												'group flex h-[40px] items-center justify-between border-2 border-transparent text-[16px] font-[600] text-uom-heritage-100 hover:bg-white has-[[data-status=active]]:border-uom-heritage-100 has-[[data-status=active]]:bg-white',
											)}
										>
											{thread.SK === renameThreadId ? (
												<>
													<input
														ref={renameThreadInputRef}
														type="text"
														value={renameThreadValue ?? ''}
														onChange={(event) => {
															setRenameThreadValue(event.target.value)
														}}
														onKeyDown={(event) => {
															if (event.key === 'Enter') {
																renameThread()
															}
														}}
														className="my-[4px] ml-[4px] w-full overflow-hidden rounded-[2px] border border-uom-blue-light-100 py-0 pl-[4px]"
													/>
													<button
														onClick={() => {
															renameThread()
														}}
														className="ml-[8px] mr-[4px] flex size-[32px] shrink-0 items-center justify-center hover:bg-uom-blue-light-25"
													>
														<MdCheck className="h-[16px]" />
													</button>
												</>
											) : (
												<>
													<Tooltip placement="right">
														<TooltipTrigger className="flex-1 overflow-hidden overflow-ellipsis text-left">
															<Link
																to="/course/$courseId/threads/$threadId"
																params={{courseId, threadId: thread.SK}}
																className={clsx(renameThreadId != null && 'pointer-events-none', 'flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap pl-[8px] text-left')}
															>
																{thread.thread_name}
															</Link>
														</TooltipTrigger>
														<TooltipContent className="z-[999] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white md:ml-[60px]">{thread.thread_name}</TooltipContent>
													</Tooltip>
													<Menu
														as="div"
														className="group-hover:visible data-[open]:visible md:invisible"
													>
														{({close}) => (
															<>
																<Tooltip placement="right">
																	<TooltipTrigger>
																		<MenuButton
																			className="ml-[8px] mr-[4px] flex size-[32px] shrink-0 items-center justify-center hover:bg-uom-blue-light-25"
																			onClick={() => {
																				setThreadListScrollHeight(threadListScrollRef.current?.scrollTop ?? 0)
																			}}
																		>
																			<HiEllipsisVertical className="size-[20px] shrink-0" />
																		</MenuButton>
																	</TooltipTrigger>
																	<TooltipContent className="z-[999] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white">More</TooltipContent>
																</Tooltip>
																<div className="absolute">
																	<MenuItems
																		onMouseLeave={() => {
																			close()
																		}}
																		className="relative left-[-152px] top-[-4px] z-10 mt-2 w-[140px] min-w-[240px] border border-uom-grey-light-50 bg-white text-[16px] md:left-[10px]"
																		style={{top: `-${threadListScrollHeight + 30}px`}}
																	>
																		<MenuItem>
																			<button
																				className="block w-full px-4 py-2 text-left data-[active]:bg-uom-grey-light-25"
																				onMouseOver={() => {
																					void queryClient.prefetchQuery({
																						queryKey: ['projects', botConfig?.project_id, 'threads', thread.SK],
																					})
																				}}
																				onClick={() => {
																					void (async () => {
																						await queryClient.prefetchQuery({
																							queryKey: ['projects', botConfig?.project_id, 'threads', thread.SK],
																						})
																						const data = queryClient.getQueryData<Thread>(['projects', botConfig?.project_id, 'threads', thread.SK])
																						if (data != null) {
																							copyThreadToClipboard(data)
																						}
																					})()
																				}}
																			>
																				Copy entire thread
																			</button>
																		</MenuItem>
																		<MenuItem>
																			<button
																				className="block w-full px-4 py-2 text-left data-[active]:bg-uom-grey-light-25"
																				onClick={() => {
																					setRenameThreadId(thread.SK)
																				}}
																			>
																				Rename thread
																			</button>
																		</MenuItem>
																		<MenuItem>
																			<button
																				onClick={() => {
																					setDeleteThreadId(thread.SK)
																				}}
																				className="block w-full px-4 py-2 text-left text-uom-red-light-100 data-[active]:bg-uom-grey-light-25"
																			>
																				Delete thread
																			</button>
																		</MenuItem>
																	</MenuItems>
																</div>
															</>
														)}
													</Menu>
												</>
											)}
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			<div className="flex min-h-[59px] items-center gap-x-[8px] border-t border-uom-grey-dark-25 p-[16px] md:p-[12px]">
				<div className="shrink-0 bg-uom-blue-light-100 p-[8px]">
					<ImpactIcon className="size-[16px]" />
				</div>

				<Tooltip
					placement={isMobile ? 'right' : 'top'}
					enabled={isOverflowed}
				>
					<TooltipTrigger
						ref={userDetailsContainerRef}
						className={clsx('h-[32px] w-full whitespace-nowrap', isOverflowed && 'cursor-pointer')}
					>
						<div
							ref={userDetailsNameRef}
							className="h-[16px] w-max max-w-full overflow-hidden overflow-ellipsis text-[14px] font-[700] leading-none"
						>
							{context?.context.user_name}
						</div>
						<div
							ref={userDetailsEmailRef}
							className="mb-[-2px] h-[16px] w-max max-w-full overflow-hidden overflow-ellipsis text-[14px] font-[400] leading-none text-uom-grey-dark-75"
						>
							{context?.context.canvas_user_email_id}
						</div>
					</TooltipTrigger>

					<TooltipContent className="z-[999] mb-[100px] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white md:mb-0 md:ml-[16px]">
						<div>{context?.context.user_name}</div>
						<div>{context?.context.canvas_user_email_id}</div>
					</TooltipContent>
				</Tooltip>
			</div>
			<UniqueNameRequiredModal
				open={uniqueNameRequiredType != null}
				onClose={() => {
					setUniqueNameRequiredType(null)
				}}
				itemType={uniqueNameRequiredType ?? ''}
			/>
		</>
	)
}

function groupThreadsByDate(threads: ThreadSummery[]) {
	const result: Record<string, ThreadSummery[] | null> = {}

	const currentDate = new Date()
	const last30DaysDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000)

	for (const thread of threads) {
		const modifiedDate = new Date(thread.thread_modified_datetime)
		// Check if the thread is from today
		if (Date.now() - modifiedDate.getTime() < 24 * 60 * 60 * 1000) {
			if (!result.Today) {
				result.Today = []
			}
			result.Today.push(thread)
			continue
		}

		// Check if the thread is from the last 30 days
		if (modifiedDate >= last30DaysDate) {
			if (!result.Last_30_days) {
				result.Last_30_days = []
			}
			result.Last_30_days.push(thread)
			continue
		}

		// Get the month and year of the modified date
		const monthKey = modifiedDate.toLocaleString('default', {month: 'long'})
		const yearKey = modifiedDate.getFullYear().toString()

		// Add the thread to the corresponding month array if it's from the current year
		if (modifiedDate.getFullYear() === currentDate.getFullYear()) {
			if (!result[monthKey]) {
				result[monthKey] = []
			}
			result[monthKey].push(thread)
			continue // Move to the next thread item
		}

		// Add the thread to the corresponding year and month array if it's from a previous year
		const yearMonthKey = `${monthKey}_${yearKey}`
		if (!result[yearMonthKey]) {
			result[yearMonthKey] = []
		}
		result[yearMonthKey].push(thread)
	}

	return result
}
