import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'
import {useNavigate} from '@tanstack/react-router'
import {useAtom} from 'jotai'
import {MdCheck, MdExpandMore} from 'react-icons/md'
import {useBotConfig, useBots} from '~/api'
import {Route} from '~/routes/course.$courseId._student-layout'
import {isShowMobileMenuAtom} from '~/state'

export function CourseSwitcher() {
	const bots = useBots().bots?.filter((bot) => bot.enabled)
	const {courseId} = Route.useParams()
	const [, setIsShowMobileMenu] = useAtom(isShowMobileMenuAtom)
	const navigate = useNavigate()
	const botConfig = useBotConfig()

	return (
		<Listbox
			as="div"
			id="course-switcher"
			className="spotlightable shrink-0"
			value={courseId}
			onChange={(newCourseId) => {
				setIsShowMobileMenu(false)
				void navigate({to: '/course/$courseId/chat', params: {courseId: newCourseId}})
			}}
		>
			<div className="relative h-[40px] w-full">
				<ListboxButton
					disabled={!bots || bots.length <= 0}
					className="relative h-[40px] w-full border-b border-uom-grey-dark-25 bg-uom-grey-light-25 pl-[16px] pr-[42px] text-left text-[14px] font-[700] disabled:cursor-not-allowed md:bg-white md:pl-[12px] md:pr-[24px]"
				>
					<span className="block truncate">{botConfig?.tutor_bot_course_name.split('(')[0]}</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[16px] md:pr-[8px]">
						<MdExpandMore
							className="size-[21px]"
							aria-hidden="true"
						/>
					</span>
				</ListboxButton>

				<ListboxOptions className="absolute z-10 w-full border border-uom-grey-light-50 bg-white text-[16px] shadow shadow-[#dcdee3] transition-opacity duration-100 data-[closed]:opacity-0 md:ml-1 md:mt-1 md:w-[unset] md:min-w-[408px]">
					{bots?.map((bot) => (
						<ListboxOption
							key={bot.id}
							as="button"
							className="group flex h-max w-full items-center justify-between px-[16px] py-[12px] hover:bg-uom-grey-light-25"
							value={bot.sis_course_id}
						>
							<span className="h-max overflow-hidden overflow-ellipsis whitespace-nowrap group-data-[selected]:font-semibold">{bot.tutor_bot_course_name.split('(')[0]}</span>

							<span className="invisible flex shrink-0 items-center pl-[12px] group-data-[selected]:visible">
								<MdCheck
									className="size-[20px]"
									aria-hidden="true"
								/>
							</span>
						</ListboxOption>
					))}
				</ListboxOptions>
			</div>
		</Listbox>
	)
}
