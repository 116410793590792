/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as NoTokenImport } from './routes/no-token'
import { Route as CourseCourseIdImport } from './routes/course.$courseId'
import { Route as CourseCourseIdStudentLayoutImport } from './routes/course.$courseId._student-layout'
import { Route as CourseCourseIdEducatorLayoutImport } from './routes/course.$courseId._educator-layout'
import { Route as CourseCourseIdStudentLayoutRevisionImport } from './routes/course.$courseId._student-layout.revision'
import { Route as CourseCourseIdStudentLayoutChatImport } from './routes/course.$courseId._student-layout.chat'
import { Route as CourseCourseIdEducatorLayoutPromptEnhancementsImport } from './routes/course.$courseId._educator-layout.prompt-enhancements'
import { Route as CourseCourseIdEducatorLayoutLmsResourcesImport } from './routes/course.$courseId._educator-layout.lmsResources'
import { Route as CourseCourseIdEducatorLayoutAccessImport } from './routes/course.$courseId._educator-layout.access'
import { Route as CourseCourseIdStudentLayoutThreadsThreadIdImport } from './routes/course.$courseId._student-layout.threads.$threadId'

// Create/Update Routes

const NoTokenRoute = NoTokenImport.update({
  id: '/no-token',
  path: '/no-token',
  getParentRoute: () => rootRoute,
} as any)

const CourseCourseIdRoute = CourseCourseIdImport.update({
  id: '/course/$courseId',
  path: '/course/$courseId',
  getParentRoute: () => rootRoute,
} as any)

const CourseCourseIdStudentLayoutRoute =
  CourseCourseIdStudentLayoutImport.update({
    id: '/_student-layout',
    getParentRoute: () => CourseCourseIdRoute,
  } as any)

const CourseCourseIdEducatorLayoutRoute =
  CourseCourseIdEducatorLayoutImport.update({
    id: '/_educator-layout',
    getParentRoute: () => CourseCourseIdRoute,
  } as any)

const CourseCourseIdStudentLayoutRevisionRoute =
  CourseCourseIdStudentLayoutRevisionImport.update({
    id: '/revision',
    path: '/revision',
    getParentRoute: () => CourseCourseIdStudentLayoutRoute,
  } as any)

const CourseCourseIdStudentLayoutChatRoute =
  CourseCourseIdStudentLayoutChatImport.update({
    id: '/chat',
    path: '/chat',
    getParentRoute: () => CourseCourseIdStudentLayoutRoute,
  } as any)

const CourseCourseIdEducatorLayoutPromptEnhancementsRoute =
  CourseCourseIdEducatorLayoutPromptEnhancementsImport.update({
    id: '/prompt-enhancements',
    path: '/prompt-enhancements',
    getParentRoute: () => CourseCourseIdEducatorLayoutRoute,
  } as any)

const CourseCourseIdEducatorLayoutLmsResourcesRoute =
  CourseCourseIdEducatorLayoutLmsResourcesImport.update({
    id: '/lmsResources',
    path: '/lmsResources',
    getParentRoute: () => CourseCourseIdEducatorLayoutRoute,
  } as any)

const CourseCourseIdEducatorLayoutAccessRoute =
  CourseCourseIdEducatorLayoutAccessImport.update({
    id: '/access',
    path: '/access',
    getParentRoute: () => CourseCourseIdEducatorLayoutRoute,
  } as any)

const CourseCourseIdStudentLayoutThreadsThreadIdRoute =
  CourseCourseIdStudentLayoutThreadsThreadIdImport.update({
    id: '/threads/$threadId',
    path: '/threads/$threadId',
    getParentRoute: () => CourseCourseIdStudentLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/no-token': {
      id: '/no-token'
      path: '/no-token'
      fullPath: '/no-token'
      preLoaderRoute: typeof NoTokenImport
      parentRoute: typeof rootRoute
    }
    '/course/$courseId': {
      id: '/course/$courseId'
      path: '/course/$courseId'
      fullPath: '/course/$courseId'
      preLoaderRoute: typeof CourseCourseIdImport
      parentRoute: typeof rootRoute
    }
    '/course/$courseId/_educator-layout': {
      id: '/course/$courseId/_educator-layout'
      path: ''
      fullPath: '/course/$courseId'
      preLoaderRoute: typeof CourseCourseIdEducatorLayoutImport
      parentRoute: typeof CourseCourseIdImport
    }
    '/course/$courseId/_student-layout': {
      id: '/course/$courseId/_student-layout'
      path: ''
      fullPath: '/course/$courseId'
      preLoaderRoute: typeof CourseCourseIdStudentLayoutImport
      parentRoute: typeof CourseCourseIdImport
    }
    '/course/$courseId/_educator-layout/access': {
      id: '/course/$courseId/_educator-layout/access'
      path: '/access'
      fullPath: '/course/$courseId/access'
      preLoaderRoute: typeof CourseCourseIdEducatorLayoutAccessImport
      parentRoute: typeof CourseCourseIdEducatorLayoutImport
    }
    '/course/$courseId/_educator-layout/lmsResources': {
      id: '/course/$courseId/_educator-layout/lmsResources'
      path: '/lmsResources'
      fullPath: '/course/$courseId/lmsResources'
      preLoaderRoute: typeof CourseCourseIdEducatorLayoutLmsResourcesImport
      parentRoute: typeof CourseCourseIdEducatorLayoutImport
    }
    '/course/$courseId/_educator-layout/prompt-enhancements': {
      id: '/course/$courseId/_educator-layout/prompt-enhancements'
      path: '/prompt-enhancements'
      fullPath: '/course/$courseId/prompt-enhancements'
      preLoaderRoute: typeof CourseCourseIdEducatorLayoutPromptEnhancementsImport
      parentRoute: typeof CourseCourseIdEducatorLayoutImport
    }
    '/course/$courseId/_student-layout/chat': {
      id: '/course/$courseId/_student-layout/chat'
      path: '/chat'
      fullPath: '/course/$courseId/chat'
      preLoaderRoute: typeof CourseCourseIdStudentLayoutChatImport
      parentRoute: typeof CourseCourseIdStudentLayoutImport
    }
    '/course/$courseId/_student-layout/revision': {
      id: '/course/$courseId/_student-layout/revision'
      path: '/revision'
      fullPath: '/course/$courseId/revision'
      preLoaderRoute: typeof CourseCourseIdStudentLayoutRevisionImport
      parentRoute: typeof CourseCourseIdStudentLayoutImport
    }
    '/course/$courseId/_student-layout/threads/$threadId': {
      id: '/course/$courseId/_student-layout/threads/$threadId'
      path: '/threads/$threadId'
      fullPath: '/course/$courseId/threads/$threadId'
      preLoaderRoute: typeof CourseCourseIdStudentLayoutThreadsThreadIdImport
      parentRoute: typeof CourseCourseIdStudentLayoutImport
    }
  }
}

// Create and export the route tree

interface CourseCourseIdEducatorLayoutRouteChildren {
  CourseCourseIdEducatorLayoutAccessRoute: typeof CourseCourseIdEducatorLayoutAccessRoute
  CourseCourseIdEducatorLayoutLmsResourcesRoute: typeof CourseCourseIdEducatorLayoutLmsResourcesRoute
  CourseCourseIdEducatorLayoutPromptEnhancementsRoute: typeof CourseCourseIdEducatorLayoutPromptEnhancementsRoute
}

const CourseCourseIdEducatorLayoutRouteChildren: CourseCourseIdEducatorLayoutRouteChildren =
  {
    CourseCourseIdEducatorLayoutAccessRoute:
      CourseCourseIdEducatorLayoutAccessRoute,
    CourseCourseIdEducatorLayoutLmsResourcesRoute:
      CourseCourseIdEducatorLayoutLmsResourcesRoute,
    CourseCourseIdEducatorLayoutPromptEnhancementsRoute:
      CourseCourseIdEducatorLayoutPromptEnhancementsRoute,
  }

const CourseCourseIdEducatorLayoutRouteWithChildren =
  CourseCourseIdEducatorLayoutRoute._addFileChildren(
    CourseCourseIdEducatorLayoutRouteChildren,
  )

interface CourseCourseIdStudentLayoutRouteChildren {
  CourseCourseIdStudentLayoutChatRoute: typeof CourseCourseIdStudentLayoutChatRoute
  CourseCourseIdStudentLayoutRevisionRoute: typeof CourseCourseIdStudentLayoutRevisionRoute
  CourseCourseIdStudentLayoutThreadsThreadIdRoute: typeof CourseCourseIdStudentLayoutThreadsThreadIdRoute
}

const CourseCourseIdStudentLayoutRouteChildren: CourseCourseIdStudentLayoutRouteChildren =
  {
    CourseCourseIdStudentLayoutChatRoute: CourseCourseIdStudentLayoutChatRoute,
    CourseCourseIdStudentLayoutRevisionRoute:
      CourseCourseIdStudentLayoutRevisionRoute,
    CourseCourseIdStudentLayoutThreadsThreadIdRoute:
      CourseCourseIdStudentLayoutThreadsThreadIdRoute,
  }

const CourseCourseIdStudentLayoutRouteWithChildren =
  CourseCourseIdStudentLayoutRoute._addFileChildren(
    CourseCourseIdStudentLayoutRouteChildren,
  )

interface CourseCourseIdRouteChildren {
  CourseCourseIdEducatorLayoutRoute: typeof CourseCourseIdEducatorLayoutRouteWithChildren
  CourseCourseIdStudentLayoutRoute: typeof CourseCourseIdStudentLayoutRouteWithChildren
}

const CourseCourseIdRouteChildren: CourseCourseIdRouteChildren = {
  CourseCourseIdEducatorLayoutRoute:
    CourseCourseIdEducatorLayoutRouteWithChildren,
  CourseCourseIdStudentLayoutRoute:
    CourseCourseIdStudentLayoutRouteWithChildren,
}

const CourseCourseIdRouteWithChildren = CourseCourseIdRoute._addFileChildren(
  CourseCourseIdRouteChildren,
)

export interface FileRoutesByFullPath {
  '/no-token': typeof NoTokenRoute
  '/course/$courseId': typeof CourseCourseIdStudentLayoutRouteWithChildren
  '/course/$courseId/access': typeof CourseCourseIdEducatorLayoutAccessRoute
  '/course/$courseId/lmsResources': typeof CourseCourseIdEducatorLayoutLmsResourcesRoute
  '/course/$courseId/prompt-enhancements': typeof CourseCourseIdEducatorLayoutPromptEnhancementsRoute
  '/course/$courseId/chat': typeof CourseCourseIdStudentLayoutChatRoute
  '/course/$courseId/revision': typeof CourseCourseIdStudentLayoutRevisionRoute
  '/course/$courseId/threads/$threadId': typeof CourseCourseIdStudentLayoutThreadsThreadIdRoute
}

export interface FileRoutesByTo {
  '/no-token': typeof NoTokenRoute
  '/course/$courseId': typeof CourseCourseIdStudentLayoutRouteWithChildren
  '/course/$courseId/access': typeof CourseCourseIdEducatorLayoutAccessRoute
  '/course/$courseId/lmsResources': typeof CourseCourseIdEducatorLayoutLmsResourcesRoute
  '/course/$courseId/prompt-enhancements': typeof CourseCourseIdEducatorLayoutPromptEnhancementsRoute
  '/course/$courseId/chat': typeof CourseCourseIdStudentLayoutChatRoute
  '/course/$courseId/revision': typeof CourseCourseIdStudentLayoutRevisionRoute
  '/course/$courseId/threads/$threadId': typeof CourseCourseIdStudentLayoutThreadsThreadIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/no-token': typeof NoTokenRoute
  '/course/$courseId': typeof CourseCourseIdRouteWithChildren
  '/course/$courseId/_educator-layout': typeof CourseCourseIdEducatorLayoutRouteWithChildren
  '/course/$courseId/_student-layout': typeof CourseCourseIdStudentLayoutRouteWithChildren
  '/course/$courseId/_educator-layout/access': typeof CourseCourseIdEducatorLayoutAccessRoute
  '/course/$courseId/_educator-layout/lmsResources': typeof CourseCourseIdEducatorLayoutLmsResourcesRoute
  '/course/$courseId/_educator-layout/prompt-enhancements': typeof CourseCourseIdEducatorLayoutPromptEnhancementsRoute
  '/course/$courseId/_student-layout/chat': typeof CourseCourseIdStudentLayoutChatRoute
  '/course/$courseId/_student-layout/revision': typeof CourseCourseIdStudentLayoutRevisionRoute
  '/course/$courseId/_student-layout/threads/$threadId': typeof CourseCourseIdStudentLayoutThreadsThreadIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/no-token'
    | '/course/$courseId'
    | '/course/$courseId/access'
    | '/course/$courseId/lmsResources'
    | '/course/$courseId/prompt-enhancements'
    | '/course/$courseId/chat'
    | '/course/$courseId/revision'
    | '/course/$courseId/threads/$threadId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/no-token'
    | '/course/$courseId'
    | '/course/$courseId/access'
    | '/course/$courseId/lmsResources'
    | '/course/$courseId/prompt-enhancements'
    | '/course/$courseId/chat'
    | '/course/$courseId/revision'
    | '/course/$courseId/threads/$threadId'
  id:
    | '__root__'
    | '/no-token'
    | '/course/$courseId'
    | '/course/$courseId/_educator-layout'
    | '/course/$courseId/_student-layout'
    | '/course/$courseId/_educator-layout/access'
    | '/course/$courseId/_educator-layout/lmsResources'
    | '/course/$courseId/_educator-layout/prompt-enhancements'
    | '/course/$courseId/_student-layout/chat'
    | '/course/$courseId/_student-layout/revision'
    | '/course/$courseId/_student-layout/threads/$threadId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  NoTokenRoute: typeof NoTokenRoute
  CourseCourseIdRoute: typeof CourseCourseIdRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  NoTokenRoute: NoTokenRoute,
  CourseCourseIdRoute: CourseCourseIdRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/no-token",
        "/course/$courseId"
      ]
    },
    "/no-token": {
      "filePath": "no-token.tsx"
    },
    "/course/$courseId": {
      "filePath": "course.$courseId.tsx",
      "children": [
        "/course/$courseId/_educator-layout",
        "/course/$courseId/_student-layout"
      ]
    },
    "/course/$courseId/_educator-layout": {
      "filePath": "course.$courseId._educator-layout.tsx",
      "parent": "/course/$courseId",
      "children": [
        "/course/$courseId/_educator-layout/access",
        "/course/$courseId/_educator-layout/lmsResources",
        "/course/$courseId/_educator-layout/prompt-enhancements"
      ]
    },
    "/course/$courseId/_student-layout": {
      "filePath": "course.$courseId._student-layout.tsx",
      "parent": "/course/$courseId",
      "children": [
        "/course/$courseId/_student-layout/chat",
        "/course/$courseId/_student-layout/revision",
        "/course/$courseId/_student-layout/threads/$threadId"
      ]
    },
    "/course/$courseId/_educator-layout/access": {
      "filePath": "course.$courseId._educator-layout.access.tsx",
      "parent": "/course/$courseId/_educator-layout"
    },
    "/course/$courseId/_educator-layout/lmsResources": {
      "filePath": "course.$courseId._educator-layout.lmsResources.tsx",
      "parent": "/course/$courseId/_educator-layout"
    },
    "/course/$courseId/_educator-layout/prompt-enhancements": {
      "filePath": "course.$courseId._educator-layout.prompt-enhancements.tsx",
      "parent": "/course/$courseId/_educator-layout"
    },
    "/course/$courseId/_student-layout/chat": {
      "filePath": "course.$courseId._student-layout.chat.tsx",
      "parent": "/course/$courseId/_student-layout"
    },
    "/course/$courseId/_student-layout/revision": {
      "filePath": "course.$courseId._student-layout.revision.tsx",
      "parent": "/course/$courseId/_student-layout"
    },
    "/course/$courseId/_student-layout/threads/$threadId": {
      "filePath": "course.$courseId._student-layout.threads.$threadId.tsx",
      "parent": "/course/$courseId/_student-layout"
    }
  }
}
ROUTE_MANIFEST_END */
