import {Link, useMatches} from '@tanstack/react-router'
import {clsx} from 'clsx'
import {useAtom} from 'jotai'
import {MdDirectionsWalk, MdMenu} from 'react-icons/md'
import {useBotConfig} from '~/api.ts'
import {Tooltip, TooltipContent, TooltipTrigger} from '~/components/Tooltip.tsx'
import {Route} from '~/routes/course.$courseId'
import {isShowMobileMenuAtom, reshowOnboardingAtom} from '~/state.ts'

export const Navbar = () => {
	const botConfig = useBotConfig()
	const {courseId} = Route.useParams()
	const [, setShowMobileMenu] = useAtom(isShowMobileMenuAtom)
	const isEducatorLayout = useMatches({
		select: (matches) =>
			matches.some((match) => {
				return match.id.endsWith('/_educator-layout')
			}),
	})

	const [, setReshowOnboarding] = useAtom(reshowOnboardingAtom)

	return (
		<nav
			className={clsx(
				isEducatorLayout ? 'px-[32px] lg:px-[80px]' : 'px-[16px] md:pl-[12px] md:pr-[32px] lg:pr-[84px]',
				'flex shrink-0 items-center justify-between gap-x-[24px] gap-y-[8px] bg-uom-heritage-100 py-[8px] text-white',
			)}
		>
			<div className={clsx(isEducatorLayout ? 'md:justify-between' : 'justify-between', 'flex w-full items-center gap-x-[12px] gap-y-[8px] md:w-auto md:grow-0 md:gap-x-[24px] lg:gap-x-[48px]')}>
				<Link
					to={isEducatorLayout ? '/course/$courseId/access' : '/course/$courseId/chat'}
					params={{courseId}}
					className="flex flex-shrink-0 items-center"
				>
					<img
						alt="University of Melbourne"
						src="/uom_logo.svg"
						className="hidden h-[48px] lg:block"
					/>
					<img
						alt="University of Melbourne"
						src="/uom_logo_vertical.svg"
						className="h-[48px] lg:hidden"
					/>
				</Link>
				<Link
					to={isEducatorLayout ? '/course/$courseId/access' : '/course/$courseId/chat'}
					params={{courseId}}
					className="flex flex-shrink-0 items-center"
				>
					<img
						alt="Aila"
						src="/aila_logo.svg"
						className="h-[26px] lg:block"
					/>
				</Link>
				{!isEducatorLayout && (
					<button
						onMouseDown={() => {
							setShowMobileMenu((prev) => !prev)
						}}
						className="flex size-[40px] flex-shrink-0 items-center justify-center md:hidden"
					>
						<MdMenu className="size-[24px]" />
					</button>
				)}
			</div>
			<div className="flex items-center">
				{botConfig != null && (
					<Tooltip placement="bottom">
						<TooltipTrigger
							className={clsx(
								!isEducatorLayout && 'hidden md:block',
								'h-min w-max shrink-0 overflow-x-hidden overflow-ellipsis whitespace-nowrap rounded-[2px] bg-uom-blue-light-25 px-[8px] py-[2px] text-[14px] font-[400] text-uom-heritage-100 md:max-w-[380px]',
							)}
						>
							{botConfig.tutor_bot_course_name}
						</TooltipTrigger>
						<TooltipContent className="z-[999] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white">{botConfig.tutor_bot_course_name}</TooltipContent>
					</Tooltip>
				)}
				<a
					href="https://q.surveys.unimelb.edu.au/jfe/form/SV_835DYg5YmbKAfGu"
					target="_blank"
					rel="noreferrer"
					className="ml-[24px] hidden h-[32px] items-center border border-white px-[8px] py-[3px] text-[18px] hover:border-uom-blue-light-100 hover:text-uom-blue-light-100 active:!border-uom-blue-light-25 md:flex"
				>
					Feedback
				</a>

				{!isEducatorLayout && (
					<Tooltip placement="bottom">
						<TooltipTrigger>
							<button
								onClick={() => {
									console.log('reshowOnboarding')
									setReshowOnboarding(true)
								}}
								className="ml-[8px] hidden h-[32px] items-center border border-white px-[8px] py-[3px] text-[18px] hover:bg-uom-heritage-75 active:bg-uom-heritage-50 md:flex"
							>
								<MdDirectionsWalk className="size-[20px]" />
							</button>
						</TooltipTrigger>
						<TooltipContent className="z-[999] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white">Aila walkthrough</TooltipContent>
					</Tooltip>
				)}
			</div>
		</nav>
	)
}
