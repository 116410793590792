import {createFileRoute, Link} from '@tanstack/react-router'
import {clsx} from 'clsx'
import {MdAccessTime, MdCheckCircle, MdInfo, MdLock, MdUnpublished} from 'react-icons/md'
import {useBotConfig, useUpdateBotConfig} from '~/api.ts'
import {LockOpen} from '~/assets/LockOpen.tsx'
import {EducatorTabs} from '~/components/EducatorTabs.tsx'
import {EducatorSiteDisabled} from '~/components/SiteDisabled.tsx'
import {Toggle} from '~/components/Toggle.tsx'
import {isContinuePollingStatus} from '~/model.ts'
import {msToHoursMinutes} from '~/utils/utils'

export const Route = createFileRoute('/course/$courseId/_educator-layout/access')({
	component: Access,
})

function Access() {
	const {courseId} = Route.useParams()
	const botConfig = useBotConfig()
	const {mutate: mutateUpdateBotConfig} = useUpdateBotConfig()

	return (
		<>
			<div className="flex min-h-[32px] shrink-0 items-center justify-between">
				<EducatorTabs />
			</div>
			<div className="mt-[24px]">
				<h1 className="font-lead text-[30px] font-[500] leading-[110%] text-uom-heritage-100">Access</h1>
				<p className={clsx(botConfig?.locked && !botConfig.is_locked_by_current_user && 'min-h-[39px]', 'mt-[8px] text-[18px]')}>Manage admin and student access to the learning assistant</p>

				<EducatorSiteDisabled>
					<div className="w-full max-w-content md:w-2/3">
						<h2 className="mt-[24px] text-[20px] font-[600] leading-[140%] text-uom-heritage-100">Admin access</h2>
						<p className="mt-[4px] leading-[120%]">To make changes to LMS resources or prompt enhancements, you must first lock other admin users out of the product.</p>
						{isContinuePollingStatus(botConfig?.status) && (
							<div className="my-[12px] flex max-w-content items-center gap-[10px] bg-uom-blue-light-25 p-[12px]">
								<div className="shrink-0 bg-uom-blue-light-50 p-[6px]">
									<MdInfo className="size-[20px]" />
								</div>
								<div className="text-uom-heritage-100">You cannot unlock the admin access while the data set it updating.</div>
							</div>
						)}
						<div className="mt-[12px] flex justify-between gap-x-[24px] border border-uom-grey-dark-25 p-[24px]">
							<div>
								<h3 className="text-[20px] font-[600] leading-[140%] text-uom-heritage-100">Lock for editing</h3>
								<p className="mt-[4px]">Toggle on to edit LMS resources and prompt enhancement. This will prevent simultaneous editing by other admins.</p>
								<div className="flex flex-wrap items-center gap-x-[12px]">
									<div
										className={clsx(
											!botConfig?.locked ? 'bg-uom-grey-light-25' : 'bg-uom-blue-light-25',
											'mt-[12px] flex max-w-max items-center gap-x-[4px] rounded-[2px] px-[8px] py-[4px] text-uom-heritage-100',
										)}
									>
										{!botConfig?.locked ? <MdLock className="size-[16px] shrink-0" /> : <LockOpen className="size-[16px] shrink-0" />}
										<div>Currently {!botConfig?.locked ? 'unlocked' : 'locked'}</div>
									</div>
									{botConfig?.locked && (
										<div className="mt-[12px] flex max-w-max items-center gap-x-[4px] rounded-[2px] bg-uom-blue-light-25 px-[8px] py-[4px] text-uom-heritage-100">
											<MdAccessTime className="size-[16px] shrink-0" />
											<div>
												If not unlocked, access will auto-unlock in <span className="font-[700]">{botConfig.autoUnlockAtMs && msToHoursMinutes(botConfig.autoUnlockAtMs - Date.now())}</span>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="flex items-center justify-center">
								<Toggle
									checked={botConfig?.locked ?? false}
									onChange={(checked) => {
										mutateUpdateBotConfig({locked: checked})
									}}
									disabled={isContinuePollingStatus(botConfig?.status)}
								/>
							</div>
						</div>

						<h2 className="mt-[24px] text-[20px] font-[600] leading-[140%] text-uom-heritage-100">Student access</h2>
						<p className="mt-[4px] leading-[120%]">Toggle the visibility of the learning assistant in the LMS. Before updating the database, ensure the learning assistant is hidden.</p>
						{botConfig?.status !== 'Ready' && !isContinuePollingStatus(botConfig?.status) && (
							<div className="mt-[12px] flex items-center gap-[10px] bg-uom-blue-light-25 p-[12px]">
								<div className="shrink-0 bg-uom-blue-light-50 p-[6px]">
									<MdInfo className="size-[20px]" />
								</div>
								<div className="text-uom-heritage-100">
									You cannot show the learning assistant in the LMS until the data set is created and ready within the{' '}
									<Link
										to="/course/$courseId/lmsResources"
										params={{courseId}}
										className="underline"
									>
										LMS resources tab
									</Link>
								</div>
							</div>
						)}
						<div className="mt-[12px] flex justify-between gap-x-[24px] border border-uom-grey-dark-25 p-[24px]">
							<div>
								<h3 className="text-[20px] font-[600] leading-[140%] text-uom-heritage-100">Show learning assistant in LMS</h3>
								<p className="mt-[4px]">Toggle on to display and grant students access to the learning assistant within the LMS.</p>
								<div className="flex items-center gap-x-[12px]">
									<div
										className={clsx(
											botConfig?.enabled ? 'bg-uom-blue-light-25' : 'bg-uom-grey-light-25',
											'mt-[12px] flex max-w-max items-center gap-x-[4px] rounded-[2px] px-[8px] py-[4px] text-uom-heritage-100',
										)}
									>
										{botConfig?.enabled ? <MdCheckCircle className="size-[16px]" /> : <MdUnpublished className="size-[16px]" />}
										<div>Currently {botConfig?.enabled ? 'showing' : 'hidden'}</div>
									</div>
								</div>
							</div>
							<div className="flex items-center justify-center">
								<Toggle
									checked={botConfig?.enabled ?? false}
									onChange={(checked) => {
										mutateUpdateBotConfig({enabled: checked})
									}}
									disabled={botConfig?.status !== 'Ready'}
								/>
							</div>
						</div>
					</div>
				</EducatorSiteDisabled>
			</div>
		</>
	)
}
